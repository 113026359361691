<template>
  <div class="types">
    <div class="item-con" v-for="item of list" :key="item.id">
      <BagListCard :item="item" :backfilling="backfilling" @buy="handleBuy" />
    </div>
    <div class="no-data" v-if="list.length === 0">
      <img src="../../../../public/images/no-data.png" />
      <span>{{ $t("record.desc13") }}</span>
    </div>
  </div>
</template>
<script>
// import BagTypeItem from "./BagTypeItem";
import BagListCard from "./BagListCard";

export default {
  components: {
    BagListCard,
  },
  props: {
    list: Array,
    isMobile: Boolean,
    backfilling: Boolean,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    handleSwiperChange(index) {
      this.activeIndex = index;
    },
    handleBuy(item) {
      this.$emit("buy", item);
    },
  },
};
</script>
<style lang="less" scoped>
.no-data {
  width: 100%;
  padding: 50px 0;

  img {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto 16px;
  }

  span {
    display: block;
    margin: 0 auto;
    //font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #a1a1a1;
  }
}
.pagination {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin: 0 4px;
    width: 16px;
    height: 3px;
    background: #fff;
    opacity: 0.2;
    transition: 0.5s;
    &.active {
      opacity: 1;
    }
  }
}

.types {
  display: flex;
  flex-wrap: wrap;
  .item-con {
    width: 33.33%;
    padding: 0 7px 14px;
    box-sizing: border-box;
  }
}
@media (max-width: 1200px) {
  .types {
    .item-con {
      width: 50%;
    }
  }
}

@media (max-width: 767px) {
  .no-data {
    width: 100%;
    padding: 60px 0;

    img {
      display: block;
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }

    span {
      display: block;
      margin: 0 auto;
      //font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 10px;
      text-align: center;
      color: #a1a1a1;
    }
  }

  .types {
    flex-direction: column;
    .item-con {
      margin-top: 10px;
      margin-left: 0;
      width: 100%;
    }
  }
}
</style>
