<template>
  <hoverborder class="item">
    <div class="status-con">

      <div class="status" v-if="item.status === 0">
        <img src="../../../../public/images/asset/assetbag/buy_underway.png" />
        <span>{{ $t("assetbag.desc82") }}</span>
      </div>
      <div class="status" v-else-if="item.status === 1">
        <img src="../../../../public/images/asset/assetbag/bag_finish.png" />
        <span>{{ $t("assetbag.desc83") }}</span>
      </div>
    </div>
    <div class="item-box">
      <div class="item-left">
        <div class="title">{{ item.name }}</div>
        <div class="img-con">
          <comimage class="img" :src="item.img" />
        </div>
      </div>
      <div class="info">
        <div class="title">{{ item.name }}</div>
        <div class="info-text">
          <div class="text-item">
            <div class="name">
              {{ $t("assetbag.desc15") }}
            </div>
            <div class="val">
              {{ common.cutXiaoNum1(item.releaseAmount) }} {{ item.releaseCoinName }}
            </div>
          </div>

          <div class="text-item">
            <div class="name">
              {{ $t("assetbag.desc16") }}
            </div>
            <div class="val">{{ common.cutXiaoNum1(item.sellAmount) }} {{ item.sellCoinName }}</div>
          </div>

          <!-- <div class="text-item">
            <div class="name">
              推荐奖励
            </div>
            <div class="val">{{ item.canBuyCount }}</div>
          </div> -->
          <div class="text-item">
            <div class="name">
              
              {{ $t("stake.desc1") }}
            </div>
            <div class="val">
              {{
                item.releaseRate
                  ? common.getValueMultip(item.releaseRate, 100)
                  : 0
              }}%
            </div>
          </div>
          <div class="text-item">
            <div class="name">
              {{ $t("assetbag.desc21") }}
              <tooltip :content="$t('assetbag.desc111')" />
            </div>
            <div class="val">
              {{
                Number(item.maxCount) === 0
                  ? $t("assetbag.desc23")
                  : item.maxCount
              }}
            </div>
          </div>
        </div>
        <div
          class="btn"
          :class="{
            disable: item.status === 1 || item.canBuyCount === 0,
          }"
          @click="handleBuy(item)"
        >
          {{ $t("assetbag.desc19") }}
        </div>
      </div>
    </div>
    <div
      class="btn btn-min"
      :class="{
        disable: item.status === 1 || item.canBuyCount === 0,
      }"
      @click="handleBuy(item)"
    >
      {{ $t("assetbag.desc19") }}
    </div>
  </hoverborder>
</template>
<script>
export default {
  props: {
    item: Object,
    backfilling: Boolean,
  },
  methods: {
    handleRateText(list) {
      const text = list.reduce((prev, cur) => {
        if (prev) {
          return `${prev}、${this.common.getValueMultip(
            cur.releaseRate,
            100
          )}%`;
        } else {
          return `${this.common.getValueMultip(cur.releaseRate, 100)}%`;
        }
      }, "");
      return text;
    },
    handleBuy(item) {
      if (item.status === 1) {
        this.$message(this.$t("assetbag.desc83"));
        return;
      } else if (item.canBuyCount === 0) {
        this.$message(this.$t("assetbag.desc126"));
        return;
      } 
      this.$emit("buy", item.id);
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  height: 100%;
  border-radius: 12px;
  padding: 18px;
  box-sizing: border-box;
  background: #181818;
  position: relative;
  overflow: hidden;
  &:hover {
    .info {
      transform: translateY(-46px);
      .title {
        opacity: 0;
      }

      .btn {
        opacity: 1;
      }
    }
  }

  .item-box {
    height: 100%;
    display: flex;
    position: relative;
    z-index: 1;
  }
  .title {
    color: #fff;
    //font-family: Bai Jamjuree;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
  .btn {
    margin-top: 20px;
    flex: 0 0 auto;
    height: 40px;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    box-sizing: border-box;
    //font-family: Bai Jamjuree;
    cursor: pointer;
    background: url("../../../../public/images/asset/assetbag/btn1_bg.png")
      center no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    &.disable {
      background-image: url("../../../../public/images/asset/assetbag/btn_disable_big.png");
    }
  }
  .btn-min {
    display: none;
  }
  .item-left {
    display: flex;
    align-items: center;
    .title {
      display: none;
    }
  }
  .status-con {
    position: absolute;
    top: 10px;
    right: 0;
    width: 78px;
    height: 64px;
    .status {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      span {
        color: #fff;
        //font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
        text-transform: capitalize;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-9.101deg);
        white-space: nowrap;
      }
    }
  }
  .img-con {
    .img {
      width: 120px;
      height: 120px;
      object-fit: contain;
    }
  }
  .info {
    margin-left: 18px;
    min-width: 0;
    position: relative;
    flex: 1;
    transition: 0.5s;
    .title {
      margin-bottom: 20px;
      transition: all 0.5s;
    }
    .info-text {
      display: flex;
      flex-wrap: wrap;
      .text-item {
        padding-right: 10px;
        margin-bottom: 16px;
        width: 50%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        transition: 0.5s;
        &:nth-child(even) {
          padding-right: 0;
        }
        &:last-child,
        &:nth-last-child(2) {
          margin-bottom: 0;
        }

        .name {
          color: #a8a8a8;
          //font-family: Bai Jamjuree;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
          text-transform: capitalize;
        }
        .val {
          margin-top: 8px;
          color: #fff;
          //font-family: Bai Jamjuree;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
        }
      }
    }
    .btn {
      transform: translateY(100%);
      position: absolute;
      bottom: -10px;
      width: 100%;
      opacity: 0;
    }
  }
}
@media (max-width: 992px) {
  .item {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .item {
    border-radius: 10px;
    padding: 15px;
    overflow: visible;
    &:hover {
      .info {
        transform: none;
        .info-text {
          .text-item:nth-last-child(1),
          .text-item:nth-last-child(2) {
            opacity: 1;
          }
        }
      }
    }
    .item-box {
      flex-direction: row;
      padding-left: 85px;
      position: relative;
      min-height: 120px;
    }
    .status-con {
      top: -24px;
      right: -8px;
    }
    .img-con {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -15px;
      .img {
        width: 90px;
        height: 90px;
      }
    }
    .btn-min {
      display: flex;
      background-image: url("../../../../public/images/asset/assetbag/btn1_bg_min.png");
    }
    .item-left {
      position: absolute;
      top: 0;
      left: 0;
      width: 85px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .title {
        display: block;
        width: 100%;
      }
    }
    .info {
      align-self: flex-start;
      margin-left: 0;
      .title {
        display: none;
      }
      .info-text {
        .text-item {
          padding-right: 5px;
        }
      }
      .btn {
        display: none;
      }
    }
  }
}
</style>
