<template>
  <div class="box" v-if="ownerType==0">
    <div class="box-title">{{ $t("assetbag.desc129") }}</div>
    <div class="line-con">
      <div class="progress">
        <div
          class="progress-num"
          :style="{
            minWidth: minFillWidth,
            width: fillWidth,
          }"
        >
          {{ fillRate }}%
        </div>
        <div
          class="limit"
          :style="{
            width: `${common.getValueMultip(
              Number(info.speedTypeList[0].fillRate),
              100
            )}%`,
          }"
          v-if="Number(info.fillRate) < 1"
        >
          <span
            >{{
              common.getValueMultip(
                Number(info.speedTypeList[0].fillRate),
                100
              )
            }}%({{ $t("assetbag.desc139") }})</span
          >
        </div>
      </div>
    </div>
    <div class="fill-num">
      <div class="num-item">
        {{ $t("assetbag.desc24") }}:{{ info.fillAmount }}
      </div>
      <div class="num-item">
        {{ $t("assetbag.desc46") }}:{{ info.canFillAmount }}
      </div>
    </div>
    <div class="total-line">
      <div class="line" />
      <span class="total">{{ fillTotal }} {{ info.fillCoinName }}</span>
    </div>
  </div>
  <div class="box">
    <div class="disable" v-if="ownerType==0&&(Number(info.fillRate) < Number(info.speedTypeList[0].fillRate))">
      {{
        $t("assetbag.desc138", {
          n: `${common.getValueMultip(
            Number(info.speedTypeList[0].fillRate),
            100
          )}%`,
        })
      }}
    </div>
    <div class="box-title" v-if="ownerType==0">{{ $t("assetbag.desc143") }}</div>

    <div class="release-progress">
      <div
        class="progress-num withdrawn"
        :style="{
          width: withdrawnWidth,
          minWidth: minWithdrawnWidth,
        }"
      >
        {{ withdrawnAmount }}
      </div>
      <div
        class="progress-num withdraw"
        :style="{
          width: withdrawWidth,
          minWidth: minWithdrawWidth,
        }"
      >
        {{ this.info.canWithdrawAmount }}
      </div>
      <div
        class="progress-num surplus"
        :style="{
          minWidth: minSurplusWidth,
        }"
      >
        {{ this.info.surplusReleaseAmount }}
      </div>
    </div>
    <div class="colors">
      <div class="color-item">
        <span
          class="color"
          :style="{
            backgroundColor: '#1767DF',
          }"
        />
        <span class="name">{{ $t("assetbag.desc145") }}</span>
      </div>
      <div class="color-item">
        <span
          class="color"
          :style="{
            backgroundColor: '#CEFB6B',
          }"
        />
        <span class="name">{{ $t("assetbag.desc146") }}</span>
      </div>
      <div class="color-item">
        <span
          class="color"
          :style="{
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
          }"
        />
        <span class="name">{{ $t("assetbag.desc147") }}</span>
      </div>
    </div>
    <div class="total-line">
      <div class="line" />
      <span class="total"
        >{{ info.releaseAmount }} {{ info.releaseCoinName }}</span
      >
    </div>
  </div>
  <div class="tips" v-if="ownerType==0">
    <div class="title">{{ $t("common.desc4") }}：</div>
    <p>
      {{
        $t("assetbag.desc140", {
          n: `${common.getValueMultip(
            Number(info.speedTypeList[0].fillRate),
            100
          )}%`,
        })
      }}
    </p>
    <p>
      {{
        $t("assetbag.desc141", {
          n1: `${common.getValueMultip(
            Number(info.speedTypeList[1].fillRate),
            100
          )}%`,
          n2: `${common.getValueMultip(
            Number(info.speedTypeList[0].releaseRate),
            100
          )}%`,
        })
      }}
    </p>
    <p>
      {{
        $t("assetbag.desc142", {
          n1: `${common.getValueMultip(
            Number(info.speedTypeList[1].fillRate),
            100
          )}%`,
          n2: `${common.getValueMultip(
            Number(info.speedTypeList[1].releaseRate),
            100
          )}%`,
          n3: fillTotal,
        })
      }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    info: Object,
    ownerType:Number,
  },
  computed: {
    blockAmount() {
      return (
        this.info.releaseAmount - this.info.fillAmount - this.info.canFillAmount
      );
    },
    fillTotal() {
      return this.common.getValueAdd(
        Number(this.info.fillAmount),
        Number(this.info.canFillAmount)
      );
    },
    blockWidth() {
      return `${this.common.getValueMultip(
        this.blockAmount / this.info.releaseAmount,
        100
      )}%`;
    },
    blockRate() {
      const rateInfo = this.info.speedTypeList.find(
        (item) => Number(item.fillRate) >= 1
      );
      return `${this.common.getValueMultip(rateInfo.releaseRate, 100)}%`;
    },
    fillRate() {
      if (!this.info.fillRate) {
        return `0`;
      }
      return `${this.common.numToFixed(
        this.common.getValueMultip(this.info.fillRate, 100),
        2
      )}`;
    },
    fillWidth() {
      return `${this.fillRate}%`;
    },
    minFillWidth() {
      return Number(this.fillRate) === 0
        ? 0
        : `${this.fillRate.toString().length * 12}px`;
    },
    withdrawnAmount() {
      return this.common.getValueMinus(
        Number(this.info.releasedAmount),
        Number(this.info.canWithdrawAmount)
      );
    },
    withdrawnWidth() {
      return `${(this.withdrawnAmount / this.info.releaseAmount) * 100}%`;
    },
    minWithdrawnWidth() {
      return Number(this.withdrawnAmount) === 0
        ? 0
        : `${this.withdrawnAmount.toString().length * 12}px`;
    },
    withdrawWidth() {
      return `${
        (this.info.canWithdrawAmount / this.info.releaseAmount) * 100
      }%`;
    },
    minWithdrawWidth() {
      return Number(this.info.canWithdrawAmount) === 0
        ? 0
        : `${this.info.canWithdrawAmount.toString().length * 12}px`;
    },
    minSurplusWidth() {
      return Number(this.info.surplusReleaseAmount) === 0
        ? 0
        : `${this.info.surplusReleaseAmount.toString().length * 12}px`;
    },
  },
  methods: {
    findSecondLargestFillRateReleaseRate(arr) {
      if (arr.length < 2) {
        return "Array length must be at least 2";
      }
      let largestFillRate = -Infinity;
      let secondLargestFillRate = -Infinity;

      for (const obj of arr) {
        const fillRate = obj.fillRate;

        if (fillRate > largestFillRate) {
          secondLargestFillRate = largestFillRate;
          largestFillRate = fillRate;
        } else if (
          fillRate > secondLargestFillRate &&
          fillRate !== largestFillRate
        ) {
          secondLargestFillRate = fillRate;
        }
      }

      for (const obj of arr) {
        if (obj.fillRate === secondLargestFillRate) {
          return obj.releaseRate;
        }
      }
      return 0;
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  margin-bottom: 20px;
  border-radius: 15px;
  padding: 20px 16px;
  background: rgba(52, 52, 52, 0.8);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  .disable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(87, 85, 85, 0.84);
    backdrop-filter: blur(2px);
    color: #fff;
    text-align: center;
    //font-family: Bai Jamjuree;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &-title {
    color: #fff;
    //font-family: Bai Jamjuree;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
  }
  .line-con {
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    margin-top: 40px;
    display: flex;
    .progress {
      min-width: 0;
      flex: 1;
      position: relative;
      display: flex;
      .limit {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-right: 1px solid #ff3131;
        span {
          padding-bottom: 8px;
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -100%);
          color: #c9fa5b;
          text-align: center;
          //font-family: Bai Jamjuree;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          white-space: nowrap;
        }
      }
      &-num {
        flex: 0 0 auto;
        height: 100%;
        color: #000;
        text-align: center;
        //font-family: Bai Jamjuree;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        background: #c9fa5b;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px 0 0 4px;
        overflow: hidden;
      }
      .line-item {
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        padding-bottom: 16px;
        //   &:nth-child(odd) {
        //   }
        //   &:nth-child(even) {
        //     bottom: 0;
        //     transform: translateY(100%);
        //   }
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
        //font-family: Bai Jamjuree;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 1em;
        &:first-child {
          border-radius: 0 0 4px 4px;
        }
        &:last-child {
          border-radius: 4px 4px 0 0;
        }
      }
    }
    .block {
      flex: 0 0 auto;
      color: #fff;
      text-align: center;
      //font-family: Bai Jamjuree;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      border-radius: 0 4px 4px 0;
      background: #1767df;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    .rate {
      width: 100%;
      padding-bottom: 16px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      color: rgba(255, 255, 255, 0.6);
      text-align: center;
      //font-family: Bai Jamjuree;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }
  }
  .fill-num {
    margin-top: 12px;
    color: #fff;
    //font-family: Bai Jamjuree;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    display: flex;
    .num-item {
      flex: 1;
    }
  }
  .total-line {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    .line {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.3);
    }
    .total {
      margin-top: 10px;
      color: #fff;
      text-align: center;
      //font-family: Bai Jamjuree;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
  }
  .release-rate {
    padding: 20px 0 16px;
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
    //font-family: Bai Jamjuree;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  }
  .release-progress {
    margin-top: 20px;
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);

    display: flex;
    overflow: hidden;
    .progress-num {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 1em;
      overflow: hidden;
      &.withdrawn {
        color: #fff;
        background-color: #1767df;
      }
      &.withdraw {
        color: #000;
        background-color: #cefb6b;
      }
      &.surplus {
        color: #fff;
        flex: 1;
      }
    }
  }
  .colors {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .color-item {
      margin-top: 3px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      .color {
        display: inline-block;
        flex: 0 0 auto;
        width: 10px;
        height: 10px;
        margin-right: 2px;
      }
      .name {
        color: #fff;
        //font-family: Bai Jamjuree;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
}
.tips {
  color: #f94040;
  //font-family: Bai Jamjuree;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
}
@media (max-width: 767px) {
  .box {
    margin-bottom: 16px;
    padding: 12px;
    &-title {
      font-size: 14px;
    }
    .line-con {
      margin-top: 37px;
      height: 28px;
      .rate {
        padding-bottom: 10px;
      }
    }
    .fill-num {
      margin-top: 10px;
    }
    .total-line {
      margin-top: 12px;
      .total {
        margin-top: 8px;
        font-size: 14px;
      }
    }
    .release-rate {
      padding: 16px 0 10px;
    }
    .release-progress {
      height: 28px;
      .progress-num {
        font-size: 12px;
      }
    }
  }
}
</style>
