<template>
  <div class="detail">
    <div class="item">
      <div class="name">{{ $t("assetbag.desc3") }}</div>
      <div class="item-right">
        <div class="val">{{ info.name }}</div>
      </div>
    </div>
    <div class="item" v-if="ownerType==0">
      <div class="name">{{ $t("assetbag.desc58") }}</div>
      <div class="item-right">
        <div class="val">
          {{
            info.backfillStatus === 0
              ? $t("assetbag.desc60")
              : $t("assetbag.desc59")
          }}
        </div>
      </div>
    </div>
    <div class="item">
      <div class="name">{{ $t("assetbag.desc15") }}</div>
      <div class="item-right">
        <div class="val">
          {{ common.cutXiaoNum1(info.releaseAmount) }} {{ info.releaseCoinName }}
        </div>
      </div>
    </div>
    <div class="item" v-if="ownerType==0">
      <div class="name">{{ $t("assetbag.desc24") }}</div>
      <div class="item-right">
        <div class="val">{{common.cutXiaoNum1( info.fillAmount) }} {{ info.fillCoinName }}</div>
      </div>
    </div>
    <div class="item" v-if="ownerType==0">
      <div class="name">{{ $t("assetbag.desc46") }}</div>
      <div class="item-right">
        <div class="val">{{common.cutXiaoNum1( info.canFillAmount) }} {{ info.fillCoinName }}</div>
      </div>
    </div>
    <div class="item" v-if="ownerType==0">
      <div class="name">{{ $t("assetbag.desc25") }}</div>
      <div class="item-right">
        <div class="val">
          {{ common.numToFixed(common.getValueMultip(info.fillRate, 100), 2) }}%
        </div>
      </div>
    </div>
    <div class="item">
      <div class="name">{{ $t("assetbag.desc26") }}</div>
      <div class="item-right">
        <div class="val">
          {{
            info.releaseRate ? common.getValueMultip(info.releaseRate, 100) : 0
          }}%
        </div>
      </div>
    </div>

    <div class="line" />

    <div class="item">
      <div class="name">{{ $t("assetbag.desc61") }}</div>
      <div class="item-right">
        <div class="val">{{ common.cutXiaoNum1(info.releaseDay) }} {{ info.releaseCoinName }}</div>
      </div>
    </div>
    <div class="item">
      <div class="name">{{ $t("assetbag.desc62") }}</div>
      <div class="item-right">
        <div class="val">
          {{ common.cutXiaoNum1(info.releasedAmount) }} {{ info.releaseCoinName }}
        </div>
      </div>
    </div>
    <div class="item">
      <div class="name">{{ $t("assetbag.desc63") }}</div>
      <div class="item-right">
        <div class="val">
          {{ common.cutXiaoNum1(info.surplusReleaseAmount) }} {{ info.releaseCoinName }}
        </div>
      </div>
    </div>
    <div class="item">
      <div class="name">{{ $t("assetbag.desc64") }}</div>
      <div class="item-right">
        <div class="val">{{ common.formatDate(info.buyTime) }}</div>
      </div>
    </div>
    <div class="item">
      <div class="name">{{ $t("assetbag.desc40") }}</div>
      <div class="item-right">
        <div class="val">
          {{
            Number(info.payAccountType) === 1
              ? $t("new.desc7")
              : $t("new.desc8")
          }}
        </div>
      </div>
    </div>
    <div class="item">
      <div class="name">{{ $t("assetbag.desc86") }}</div>
      <div class="item-right">
        <div class="val balance">
          {{ common.cutXiaoNum1(info.sellAmount) }} {{ info.sellCoinName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
    ownerType:Number,
  },
};
</script>

<style scoped lang="less">
.detail {
  border-radius: 8px;
  background: #242424;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}
.line {
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}
.item {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-top: 0;
  }
  .item-right {
    min-width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .name {
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.6);
    //font-family: Bai Jamjuree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-transform: capitalize;
  }
  .val {
    color: #fff;
    text-align: right;
    //font-family: Bai Jamjuree;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
    text-transform: capitalize;
    text-align: right;
    &.balance {
      color: #c9fa5b;
      font-size: 16px;
    }
  }
  span {
    margin-top: 8px;
    color: rgba(255, 255, 255, 0.6);
    text-align: right;
    //font-family: Bai Jamjuree;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-transform: capitalize;
  }
}
@media (max-width: 767px) {
  .detail {
    padding: 16px 12px;
    border-radius: 6px;
  }
}
</style>
