<template>
  <el-dialog v-model="_show">
    <div class="popup-con">
      <div class="head">
        <div class="title">{{ $t("assetbag.desc73") }}</div>
        <div class="close" @click="_show = false" />
      </div>
      <div class="con">
        <div class="table">
          <div class="table-head">
            <div class="tr">
              <div class="th">{{ $t("assetbag.desc78") }}</div>
              <div class="th">{{ $t("assetbag.desc120") }}</div>
              <div class="th">{{ $t("assetbag.desc67") }}</div>
              <div class="th">{{ $t("assetbag.desc68") }}</div>
              <div class="th">{{ $t("assetbag.desc66") }}</div>
              <div class="th">{{ $t("assetbag.desc58") }}</div>
            </div>
          </div>
          <div
            class="table-body"
            v-loading="loading"
            element-loading-background="transparent"
          >
            <div class="tr" v-for="(item, index) of list" :key="item.id">
              <div class="td">{{ common.formatDate1(item.releaseTime) }}</div>
              <div class="td">
                {{
                  Number(item.withdrawTime) === 0
                    ? "--"
                    : common.formatDate(item.withdrawTime)
                }}
              </div>
              <div class="td">{{ common.cutXiaoNum1(item.releaseAmount) }}</div>
              <div class="td">{{ item.releaseCoinName }}</div>
              <div class="td">{{ $t("assetbag.desc121") }}</div>
              <div class="td">
                {{
                  item.status === 0
                    ? $t("assetbag.desc76")
                    : $t("assetbag.desc75")
                }}
              </div>
            </div>
            <div class="no-data" v-if="showNull">
              <img src="../../../../public/images/no-data.png" />
              <span>{{ $t("record.desc13") }}</span>
            </div>
          </div>
        </div>
        <ComPagination
          :page="page"
          :pageSize="pageSize"
          :totalPage="totalPage"
          :total="total"
          @change="handlePageChange"
          v-if="totalPage>1"
        />
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  emits: ["update:show"],
  props: {
    show: Boolean,
    rewardsType:Number,
  },
  data() {
    return {
      showNull: false,
      finished: false,
      loading: false,
      page: 1,
      pageSize: 10,
      totalPage: 0,
      total: 0,
      list: [],
    };
  },
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(e) {
        this.$emit("update:show", e);
      },
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.page = 1;
        this.getList();
      }
    },
  },
  mounted() {
    if (Number(localStorage.getItem("ismobile")) === 1) {
      this._show = false;
    }
  },
  methods: {
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },

    getList() {
      if (this.loading) return;
      this.list = [];
      this.showNull = false;
      this.loading = true;
      this.$post(this.rewardsType==0?this.URL.assetbag.releaseRecords:this.URL.assetbag.releaseRecordsDaot, {
        page: this.page,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code !== 0) return;
        this.list = res.data.list;
        this.total = res.data.page.count;
        this.totalPage = res.data.page.totalPage;
        this.loading = false;
        if (this.list.length === 0) {
          this.showNull = true;
        }
       
      });
    },
  },
};
</script>
<style lang="less" scoped>
.no-data {
  width: 100%;
  padding: 50px 0;

  img {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto 16px;
  }

  span {
    display: block;
    margin: 0 auto;
    //font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #a1a1a1;
  }
}
.popup-con {
  width: 1000px;
  max-width: 90vw;
  border-radius: 12px;
  background: #181818;
  padding: 40px;
  box-sizing: border-box;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  .head {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    box-sizing: border-box;

    .title {
      min-width: 0;
      flex: 1;
      color: #fff;
      //font-family: Bai Jamjuree;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .close {
      margin-left: 10px;
      flex: 0 0 auto;
      width: 24px;
      height: 24px;
      background: url("../../../../public/images/icon_close.png") no-repeat
        center;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .con {
    .data {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 10px;
      .data-item {
        padding-bottom: 10px;
        margin-right: 40px;
        display: flex;
        .name {
          color: rgba(255, 255, 255, 0.6);
          //font-family: Bai Jamjuree;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
        }
        .val {
          color: #fff;
          //font-family: Bai Jamjuree;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          &.amount {
            color: #c9fa5b;
          }
        }
      }
    }
    /* 设置滚动条的宽度 */
    ::-webkit-scrollbar {
      width: 1px; /* 水平滚动条的高度 */
      height: 1px; /* 垂直滚动条的宽度 */
    }
    
    /* 可选：设置滚动条轨道的样式 */
    ::-webkit-scrollbar-track {
        background: #181818; /* 或者你想要的任何颜色 */
    }
    .table {
      &-head {
        background: #232323;
        .tr {
          height: 52px;
        }
      }
      &-body {
        box-sizing: border-box;
        max-height: 50vh;
        min-height: 256px;
        overflow-y: auto;
        .tr {
          height: 56px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        }
      }
      .tr {
        display: flex;
        padding: 0 20px;
        box-sizing: border-box;
        .th,
        .td {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          &:first-child {
            flex: 0 0 auto;
            width: 180px;
          }
          &:last-child {
            flex: 0 0 auto;
            width: 80px;
            text-align: right;
            justify-content: flex-end;
          }
        }
        .th {
          color: rgba(255, 255, 255, 0.6);
          //font-family: PingFang SC;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 13px;
          text-transform: capitalize;
        }
        .td {
          color: #fff;
          //font-family: PingFang SC;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 13px;
          text-transform: capitalize;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .popup-con {
    width: 100%;
    padding: 20px;

    .head {
      margin-bottom: 20px;
      .title {
        font-size: 18px;
      }
    }
  }
}
</style>
