<template>
  <el-dialog v-model="_show">
    <div class="popup-con">
      <div class="head">
        <div class="title">{{ $t("assetbag.desc39") }}</div>
        <div class="close" @click="_show = false" />
      </div>
      <div class="popup-scroll">
        <div class="con">
          <div class="list">
            <div class="item">
              <div class="name">{{ $t("assetbag.desc3") }}</div>
              <div class="val">{{ info.name }}</div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc16") }}</div>
              <div class="val">
                {{ common.cutXiaoNum1(info.sellAmount) }} {{ info.sellCoinName }}
              </div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc15") }}</div>
              <div class="val">
                {{ common.cutXiaoNum1(info.releaseAmount) }} {{ info.releaseCoinName }}
              </div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc40") }}</div>
              <div class="val">
                {{
                  Number(info.payAccountType) === 1
                  ? $t("new.desc7")
                  : $t("new.desc8")
                }}
              </div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc41") }}</div>
              <div class="val balance">
                {{ common.cutXiaoNum1(info.balance) }} {{ info.sellCoinName }}
              </div>
            </div>
          </div>
        </div>
        <div class="foot">
          <div class="btn-con">
            <div class="btn cancel" @click="_show = false">
              {{ $t("assetbag.desc42") }}
            </div>
            <div class="btn confirm" @click="handlePsdShow">
              {{ $t("assetbag.desc43") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <!-- 资金密码 -->
  <el-dialog v-model="showTrade" :append-to-body="true">
    <div class="modal-con">
      <img src="@/public/images/new/closeicon.png" alt="" class="close" @click="showTrade = false" />
      <div class="modal-title">{{ $t("node.desc45") }}</div>
      <div class="modal-desc">{{ $t("node.desc46") }}</div>
      <div class="modal-btn flex">
        <div class="flexcenter" @click="showTrade = false">
          {{ $t("node.desc47") }}
        </div>
        <div class="flexcenter" @click="handlerSubmit(1)">
          {{ $t("node.desc48") }}
        </div>
      </div>
    </div>
  </el-dialog>
  <!-- 谷歌密码 -->
  <el-dialog v-model="showGoogle" :append-to-body="true">
    <div class="modal-con">
      <img src="@/public/images/new/closeicon.png" alt="" class="close" @click="showGoogle = false" />
      <div class="modal-title">{{ $t("home.desc64") }}</div>
      <div class="modal-desc">{{ $t("home.desc65") }}</div>
      <div class="modal-btn flex">
        <div class="flexcenter" @click="showGoogle = false">
          {{ $t("node.desc47") }}
        </div>
        <div class="flexcenter" @click="handlerSubmit(2)">
          {{ $t("node.desc48") }}
        </div>
      </div>
    </div>
  </el-dialog>
  <PsdPopup v-model:show="psdShow" v-model:loading="loading" ref="isinout" @confirm="handleConfirm" />
  <SuccFailToast v-model:show="succFailShow" :title="$t('assetbag.desc80')"
    :content="$t('assetbag.desc81', { n:info.name})" />
</template>
<script>
import bus from "@/utils/bus";
export default {
  emits: ["update:show", "succ"],
  props: {
    show: Boolean,
    info: Object,
  },
  data() {
    return {
      psdShow: false,
      loading: false,
      succFailShow: false,
      userInfo: {},
      showGoogle: false,
      showTrade: false,
      ismobile: 0
    };
  },
  computed: {
    _show: {
      get() {
        this.userInfo = this.$store.state.userData;
        this.ismobile = localStorage.getItem("ismobile");
        return this.show;
      },
      set(e) {
        this.$emit("update:show", e);
      },
    },
  },
  methods: {
    handlerSubmit(i) {
      this.showTrade = false;
      this.showGoogle = false;
      this._show = false;
      if (this.ismobile == 1) {
        if (i == 1) {
          this.$router.push('/user/info?id=2')
        } else {
          this.$router.push('/user/info?id=4')
        }
      } else {

        bus.emit("hadleropen", i);
      }
      return;
    },
    handlePsdShow() {
      if (this.loading) return;
      if (!this.userInfo.hasBindGoogleAuth && !this.userInfo.hasSetPassword) {
        this.showGoogle = true;
        this._show = false;
        return;
      }
      if (!this.userInfo.hasSetPassword) {
        this.showTrade = true;
        this._show = false;
        return;
      }
      if (Number(this.info.balance) <= 0) {
        this.$message(
          this.$t("assetbag.desc135", { n: this.info.sellCoinName })
        );
        return;
      }

      this.psdShow = true;
    },

    handleConfirm(password) {
      if (this.loading) return;
      this.loading = true;
      this.$post(this.URL.assetbag.buyBag, {
        level: this.info.level,
        password,
      }).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.succFailShow = true;
          this.psdShow = false;
          this._show = false;
          this.$emit("succ");
        } else {
          this.$refs.isinout.sendpsd();
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.popup-con {
  width: 520px;
  border-radius: 12px;
  background: #181818;
  padding: 40px 0;
  box-sizing: border-box;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  .popup-scroll {
    padding: 0 40px;
    max-height: 85vh;
  }

  .head {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    box-sizing: border-box;

    .title {
      min-width: 0;
      flex: 1;
      color: #fff;
      //font-family: Bai Jamjuree;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .close {
      margin-left: 10px;
      flex: 0 0 auto;
      width: 24px;
      height: 24px;
      background: url("../../../../public/images/icon_close.png") no-repeat center;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }

  .con {
    .list {
      border-radius: 8px;
      background: #242424;
      display: flex;
      flex-direction: column;
      padding: 20px;
      box-sizing: border-box;
    }

    .item {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      &:first-child {
        margin-top: 0;
      }

      .name {
        margin-right: 10px;
        color: rgba(255, 255, 255, 0.6);
        //font-family: Bai Jamjuree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        text-transform: capitalize;
      }

      .val {
        min-width: 0;
        flex: 1;
        color: #fff;
        text-align: right;
        //font-family: Bai Jamjuree;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        text-transform: capitalize;
        text-align: right;

        &.balance {
          color: #c9fa5b;
          font-size: 16px;
        }
      }
    }
  }

  .foot {
    .btn-con {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;

      .btn {
        width: 210px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        text-align: center;
        //font-family: Bai Jamjuree;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        cursor: pointer;

        &.cancel {
          color: #fff;
          background-image: url("../../../../public/images/asset/assetbag/pupup_btn1_bg.png");
        }

        &.confirm {
          color: #000;
          background-image: url("../../../../public/images/asset/assetbag/pupup_btn2_bg.png");
        }
      }
    }

    .tip {
      margin-top: 20px;
      color: rgba(255, 255, 255, 0.8);
      //font-family: Bai Jamjuree;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 13px;
      text-transform: capitalize;
    }
  }
}

.modal-con {
  position: relative;
  padding: 32px;
  width: 480px;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .divestment-list {
    padding: 20px;
    border-radius: 8px;
    background: #242424;
    flex-wrap: wrap;

    .divestment-info {
      margin-bottom: 16px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      div {
        flex: 1;
      }

      p {
        flex: 0 0 40%;
        color: #fff;
        text-align: right;
      }
    }
  }

  .modal-tipsname {
    margin: 21px 0 11px;
    font-size: 14px;
    color: #fff;
    line-height: 16px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }

  .modal-tips1 {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 14px;

    div {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modal-input {
    margin: 20px 0 16px;
  }

  .modal-tips {
    font-size: 14px;
    color: #eb4343;
  }

  .modal-btncon {
    margin-top: 24px;
    height: 50px;
    background: url("../../../../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }

  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;

    div {
      flex: 0 0 197px;
      height: 50px;
      background: url("../../../../public/images/new/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../../../../public/images/new/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
}

@media (max-width: 767px) {
  .popup-con {
    width: 100%;
    padding: 20px 0;

    .popup-scroll {
      padding: 0 20px;
    }

    .head {
      margin-bottom: 20px;
      padding: 0 20px;

      .title {
        font-size: 18px;
      }
    }

    .con {
      .list {
        border-radius: 6px;
        padding: 16px;
      }

      .item {
        margin-top: 16px;

        .name,
        .val {
          font-size: 13px;
        }

        .val {
          &.balance {
            font-size: 13px;
          }
        }
      }
    }

    .foot {
      .btn-con {
        margin-top: 24px;

        .btn {
          max-width: 48%;
          width: 137px;
          height: 48px;
          font-size: 14px;
        }
      }

      .tip {
        margin-top: 16px;
        font-size: 13px;
      }
    }
  }

  .modal-con {
    width: 100% !important;
    padding: 32px 16px;

    .modal-title {
      padding-right: 40px;
    }

    .close {
      right: 16px;
    }

    .modal-btn {
      div {
        flex: 0 0 49%;
        height: 44px;
      }
    }
  }

}
</style>
