<template>
  <div class="myincome">
    <div class="myincome-top animate__animated animate__fadeInUp">
      <div class="top-info">
        <div class="mt-til">
          {{ $t("myincome.desc")
          }}<span v-if="incomeTodoInfo.lastGetTime != 0">{{ $t("myincome.desc1") }} :
            {{ common.formatDate(incomeTodoInfo.lastGetTime) || "--" }} ({{
              incomeTodoInfo.hours
            }}h)</span>
          <span v-else>{{ $t("myincome.desc1") }} : --</span>
        </div>
        <div class="mt-cen">
          <div class="mt-cen-item">
            <div class="mt-t">{{ $t("myincome.desc2") }}(DU)</div>
            <div class="mt-b">{{ incomeTodoInfo.landInComeDu }}</div>
            <div class="speed flex" v-if="incomeTodoInfo.landIncomeDuActivity != 0">
              <img src="../../public/images/new/speedicon.png" alt="" />
              +{{ common.cutXiaoNum1(incomeTodoInfo.landIncomeDuActivity) || 0 }} DU ({{ $t('market.desc206') }})
            </div>
          </div>
          <div class="mt-cen-item">
            <div class="mt-t">{{ $t("myincome.desc2") }}(DAOT)</div>
            <div class="mt-b">{{ incomeTodoInfo.landInComeDaot }}</div>
            <div class="speed flex" v-if="incomeTodoInfo.landInComeDaot != 0">
              <img src="../../public/images/new/speedicon.png" alt="" />
              +{{ common.cutXiaoNum1(incomeTodoInfo.landInComeDaot) || 0 }} DAOT ({{ $t('market.desc206') }})
            </div>
          </div>
          <div class="mt-cen-item">
            <div class="mt-t">{{ $t("myincome.desc2") }}(RC)</div>
            <div class="mt-b">{{ incomeTodoInfo.landInComeRc }}</div>
            <div class="speed flex" v-if="incomeTodoInfo.landIncomeRcActivity != 0">
              <img src="../../public/images/new/speedicon.png" alt="" />
              +{{ common.cutXiaoNum1(incomeTodoInfo.landIncomeRcActivity) || 0 }} RC ({{ $t('market.desc206') }})
            </div>
          </div>
          <div class="mt-cen-item">
            <div class="mt-t">{{ $t("myincome.desc3") }}(DU)</div>
            <div class="mt-b">{{ incomeTodoInfo.landTaxationDu }}</div>
          </div>

          <div class="mt-cen-item">
            <div class="mt-t">{{ $t("myincome.desc3") }}(DAOT)</div>
            <div class="mt-b">{{ incomeTodoInfo.landTaxationDaot }}</div>
          </div>

          <div class="mt-cen-item">
            <div class="mt-t">{{ $t("myincome.desc4") }}(RC)</div>
            <div class="mt-b">{{ incomeTodoInfo.algebraRewardRc }}</div>
          </div>
          <div class="mt-cen-item">
            <div class="mt-t flex">{{ $t("market.desc207") }}(DU) <el-tooltip class="box-item" raw-content effect="dark"
                placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc208')"></div>
                </template>

                <img src="../../public/images/new/tips.png" alt="" class="img1" />
              </el-tooltip></div>
            <div class="mt-b">{{ incomeTodoInfo.planetDU || 0 }}</div>
          </div>
          <div class="mt-cen-item">
            <div class="mt-t flex">{{ $t("market.desc207") }}(RC) <el-tooltip class="box-item" raw-content effect="dark"
                placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc208')"></div>
                </template>

                <img src="../../public/images/new/tips.png" alt="" class="img1" />
              </el-tooltip></div>
            <div class="mt-b">{{ incomeTodoInfo.planetRC || 0 }}</div>
          </div>
          <div class="mt-cen-item">
            <div class="mt-t">{{ $t("myincome.desc31") }}(DU)</div>
            <div class="mt-b">{{ incomeTodoInfo.recommendDu }}</div>
          </div>
          <div class="mt-cen-item">
            <div class="mt-t flex">
              {{ $t("assetbag.desc136") }}(DP)<el-tooltip class="box-item" raw-content effect="dark"
                :content="$t('asset.desc138')" placement="bottom">
                <img src="../../public/images/new/tips.png" alt="" class="img1" />
              </el-tooltip>
            </div>
            <div class="mt-b">{{ incomeTodoInfo.integralDp }}</div>
          </div>
          <div class="mt-bt" :class="{ on: todoAmountTotal <= 0 }" @click="handelReview">
            {{ $t("myincome.desc5") }}
          </div>
        </div>
        <div class="mt-bt" :class="{ on: todoAmountTotal <= 0 }" @click="handelReview">
          {{ $t("myincome.desc5") }}
        </div>
      </div>
      <div class="h5top-name flex">
        <div>{{ $t("market.desc124") }}</div>
        <p class="flex" @click="handlerOverview">
          <span v-if="!showOverview">{{ $t("market.desc132") }}</span><span v-else>{{ $t("planet.desc65") }}</span>
          <img src="../../public/images/new/upicon.png" :class="{ img1: showOverview }" />
        </p>
      </div>
      <div class="mt-bot" v-if="showOverview">
        <div class="mt-bot-item">
          <div class="mt-t">{{ $t("myincome.desc7") }}(DU)</div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.investmentTotal) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">{{ $t("myincome.desc7") }}(DAOT)</div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.investmentTotalDaot) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">{{ $t("myincome.desc8") }}</div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.landCount) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">{{ $t("myincome.desc9") }}</div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.planetCount) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            <div class="mt-t-name">{{ $t("market.desc207") }}(DU)</div>
            <div class="mt-t-tips">
              <span v-html="$t('market.desc208')"></span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.planetDU) || 0}}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            <div class="mt-t-name"> {{ $t("market.desc207") }}(RC)</div>
            <div class="mt-t-tips">
              <span v-html="$t('market.desc208')"></span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.planetRC) || 0}}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            <div class="mt-t-name">{{ $t("myincome.desc2") }}(DU)</div>
            <div class="mt-t-tips">
              <span>{{ $t("myincome.desc23") }}</span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.landIncomeDu) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            <div class="mt-t-name">{{ $t("myincome.desc2") }}(DAOT)</div>
            <div class="mt-t-tips">
              <span>{{ $t("myincome.desc23") }}</span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.landIncomeDaot) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            {{ $t("myincome.desc2") }}(RC)
            <div class="mt-t-tips">
              <span>{{ $t("myincome.desc24") }}</span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.landIncomeRc) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">{{ $t("myincome.desc31") }}(DU)</div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.planetRecommendDu) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            <div class="mt-t-name">{{ $t("myincome.desc11") }}(RC)</div>
            <div class="mt-t-tips">
              <span>{{ $t("myincome.desc28") }}</span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.incomeLimitRc) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
           <div class="mt-t-name"> {{ $t("assetbag.desc136") }}(DP)</div>
            <div class="mt-t-tips">
              <span>{{ $t("assetbag.desc148") }}</span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.grantsCredits) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            <div class="mt-t-name">{{ $t("myincome.desc4") }}(RC)</div>
            <div class="mt-t-tips">
              <span>{{ $t("myincome.desc25") }}</span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.algebraicGrantsRc) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            <div class="mt-t-name"> {{ $t("myincome.desc3") }}(DU)</div>
            <div class="mt-t-tips">
              <span v-html="$t('assetbag.desc148')"></span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.landRevenueDu) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            <div class="mt-t-name"> {{ $t("myincome.desc3") }}(DAOT)</div>
            <div class="mt-t-tips">
              <span v-html="$t('assetbag.desc148')"></span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.landRevenueDaot) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            <div class="mt-t-name"> {{ $t("myincome.desc10") }}(DU)</div>
            <div class="mt-t-tips">
              <span>{{ $t("myincome.desc27") }}</span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.lossEarningsDu) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            <div class="mt-t-name"> {{ $t("myincome.desc10") }}(DAOT)</div>
            <div class="mt-t-tips">
              <span>{{ $t("myincome.desc27") }}</span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.lossEarningsDaot) }}
          </div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">
            <div class="mt-t-name"> {{ $t("myincome.desc10") }}(RC)</div>
            <div class="mt-t-tips">
              <span>{{ $t("myincome.desc27") }}</span>
            </div>
          </div>
          <div class="mt-b">
            {{ common.cutXiaoNum1(statisticsInfo.lossEarningsRc) }}
          </div>
        </div>
      
      </div>
    </div>
    <div class="myincome-ex animate__animated animate__fadeInUp animate__delay-.2s">
      <div class="me-item" :class="{ on: incomeStatus == 3 }" @click="exChangeType(3)">
        {{ $t("myincome.desc12") }}
      </div>
      <div class="me-item" :class="{ on: incomeStatus == 2 }" @click="exChangeType(2)">
        {{ $t("myincome.desc13") }}
      </div>
      <div class="me-item" :class="{ on: incomeStatus == 5 }" @click="exChangeType(5)">
        {{ $t("myincome.desc10") }}
      </div>
    </div>
    <!-- <div class="myincome-title animate__animated animate__fadeInUp animate__delay-.2s">{{ $t('asset.desc86') }}</div> -->
    <div class="myincome-select flex animate__animated animate__fadeInUp animate__delay-.4s">
      <div class="select-time flex">
        <el-date-picker @change="dateSelect" v-model="value1" type="daterange" :range-separator="$t('record.desc2')"
          :start-placeholder="$t('record.desc1')" :end-placeholder="$t('record.desc3')">
        </el-date-picker>
        <el-select v-model="incomeType" :placeholder="$t('myincome.desc14')">
          <el-option :value="''" :label="$t('record.desc14')"></el-option>
          <el-option :value="1" :label="$t('myincome.desc2')"></el-option>
          <el-option :value="2" :label="$t('myincome.desc30')"></el-option>
          <el-option :value="3" :label="$t('myincome.desc31')"></el-option>
          <el-option :value="4" :label="$t('myincome.desc32')"></el-option>
          <el-option :value="5" :label="$t('myincome.desc33')"></el-option>
          <el-option :value="6" :label="$t('myincome.desc34')"></el-option>
          <el-option :value="11" :label="$t('myincome.desc64')"></el-option>
          <el-option :value="12" :label="$t('myincome.desc65')"></el-option>
          <el-option :value="13" :label="$t('myincome.desc66')"></el-option>
          <el-option :value="14" :label="$t('myincome.desc67')"></el-option>
          <el-option :value="20" :label="$t('myincome.desc68')"></el-option>
          <el-option :value="30" :label="$t('market.desc161')"></el-option>
          <el-option :value="40" :label="$t('market.desc207')+'DU'"></el-option>
          <el-option :value="41" :label="$t('market.desc219')"></el-option>
          <el-option :value="42" :label="$t('market.desc207')+'RC'"></el-option>
        </el-select>
        <el-select v-model="coinId" :placeholder="$t('myincome.desc16')">
          <el-option :value="''" :label="$t('record.desc14')"></el-option>
          <el-option :value="item.coinId" :label="item.coinName" v-for="(item, i) in iconList" :key="i"></el-option>
        </el-select>
        <el-select v-model="versionType" :placeholder="$t('myincome.desc70')">
          <el-option :value="0" :label="$t('myincome.desc71')"></el-option>
          <el-option :value="1" :label="$t('myincome.desc72')"></el-option>
        </el-select>
      </div>
      <div class="select-btn flex">
        <div class="flexcenter" @click="handlerReset">
          {{ $t("asset.desc87") }}
        </div>
        <div class="flexcenter" @click="handlerSearch">
          {{ $t("asset.desc88") }}
        </div>
      </div>
    </div>
    <div class="myincome-table animate__animated animate__fadeInUp animate__delay-.6s">
      <div class="table-thead flex">
        <div v-if="incomeStatus == 3 || incomeStatus == 5">
          {{ $t("myincome.desc17") }}
        </div>
        <div v-if="incomeStatus == 3">{{ $t("myincome.desc18") }}</div>
        <div v-if="incomeStatus == 2">{{ $t("myincome.desc17") }}</div>
        <div v-if="incomeStatus == 2">{{ $t("myincome.desc44") }}</div>
        <div>{{ $t("myincome.desc14") }}</div>
        <div>{{ $t("myincome.desc16") }}</div>
        <div>{{ $t("myincome.desc19") }}</div>
        <div>{{ $t("myincome.desc20") }}</div>
        <div v-if="incomeStatus == 3">{{ $t("myincome.desc21") }}</div>
        <div v-if="incomeStatus == 5">{{ $t("myincome.desc45") }}</div>
        <div>{{ $t("myincome.desc22") }}</div>
      </div>
      <div class="table-tbody">
        <div class="tbody-list flex" v-for="item in list" :key="item.id">
          <div class="flexcenter" v-if="incomeStatus == 3 || incomeStatus == 5">
            {{ common.formatDate1(item.sendTime) }}
          </div>
          <div class="flexcenter" v-if="incomeStatus == 3">
            {{
              item.receiveTime > 0 ? common.formatDate(item.receiveTime) : "--"
            }}
          </div>
          <div class="flexcenter" v-if="incomeStatus == 2">
            {{ item.sendTime > 0 ? common.formatDate1(item.sendTime) : "--" }}
          </div>
          <div class="flexcenter" v-if="incomeStatus == 2">
            {{ item.sendTime > 0 ? common.formatDate(item.sendTime) : "--" }}
          </div>
          <div class="flexcenter">{{ dealiIncomeType(item.incomeType) }}</div>
          <div class="flexcenter">{{ item.coinName }}</div>
          <div>
            <P>{{ common.cutXiaoNum1(item.amount) }}</P>
            <P class="speed flex" v-if="item.activityAmount != 0 && item.incomeType==6 || item.activityAmount != 0 && item.incomeType==1">
              <img src="../../public/images/new/speedicon.png" alt="" />
              +{{ common.cutXiaoNum1(item.activityAmount) || 0 }} ({{ $t('market.desc206') }})
            </P>
          </div>
          <div class="flexcenter">{{ dealiIncomeStatus(item.incomeStatus) }}</div>
          <div class="flexcenter" v-if="incomeStatus == 3">
            {{ dealiAccountType(item.accountType) }}
          </div>
          <div class="flexcenter" v-if="incomeStatus == 5">{{ $t("myincome.desc46") }}</div>
          <div class="flex flexcenter green" @click="handlerInfo(item)">
            {{ $t("record.desc15") }}
          </div>
        </div>
      </div>
      <div class="no-data" v-if="showNull">
        <img src="../../public/images/no-data.png" alt="" />
        <span>{{ $t("record.desc13") }}</span>
      </div>
    </div>
    <div class="rr-h5-time-out animate__animated animate__bounceInUp">
      <div class="rr-h5-time">
        <img src="../../public/images/asset/time-icon.png" />
        <div class="item">
          <div @click="startShow = true"></div>
          <input type="text" v-model="startTime" :placeholder="$t('record.desc1')" disabled />
        </div>
        <span>{{ $t("record.desc2") }}</span>
        <div class="item">
          <div @click="endShow = true"></div>
          <input type="text" v-model="endTime" :placeholder="$t('record.desc3')" disabled />
        </div>
      </div>
      <div class="rr-h5-select">
        <el-select v-model="incomeType" :placeholder="$t('myincome.desc14')" @change="onChage">
          <el-option :value="''" :label="$t('record.desc14')"></el-option>
          <el-option :value="1" :label="$t('myincome.desc29')"></el-option>
          <el-option :value="2" :label="$t('myincome.desc30')"></el-option>
          <el-option :value="3" :label="$t('myincome.desc31')"></el-option>
          <el-option :value="4" :label="$t('myincome.desc32')"></el-option>
          <el-option :value="5" :label="$t('myincome.desc33')"></el-option>
          <el-option :value="6" :label="$t('myincome.desc34')"></el-option>
          <el-option :value="11" :label="$t('myincome.desc64')"></el-option>
          <el-option :value="12" :label="$t('myincome.desc65')"></el-option>
          <el-option :value="13" :label="$t('myincome.desc66')"></el-option>
          <el-option :value="14" :label="$t('myincome.desc67')"></el-option>
          <el-option :value="20" :label="$t('myincome.desc68')"></el-option>
          <el-option :value="30" :label="$t('market.desc161')"></el-option>
          <el-option :value="40" :label="$t('market.desc207')+ ' '+'DU'"></el-option>
          <el-option :value="41" :label="$t('market.desc219')"></el-option>
          <el-option :value="42" :label="$t('market.desc207')+ ' '+'RC'"></el-option>
        </el-select>
        <el-select v-model="coinId" :placeholder="$t('myincome.desc16')" @change="onChage">
          <el-option :value="''" :label="$t('record.desc14')"></el-option>
          <el-option :value="item.coinId" :label="item.coinName" v-for="(item, i) in iconList" :key="i"></el-option>
        </el-select>
        <el-select v-model="versionType" :placeholder="$t('myincome.desc70')" @change="onChage">
          <el-option :value="0" :label="$t('myincome.desc71')"></el-option>
          <el-option :value="1" :label="$t('myincome.desc72')"></el-option>
        </el-select>
      </div>
    </div>
    <div class="planet-page animate__animated animate__fadeInUp animate__delay-1s">
      <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="pageSize" :current-page="page"
        :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
      </el-pagination>
    </div>
    <div class="h5-list">
      <van-list v-model:loading="loading" :loading-text="$t('quotes.desc20')" :finished="finished" @load="onLoad">
        <van-cell v-for="item in list" :key="item.id">
          <div class="h5list-con" @click="handlerInfoH5(item)">
            <div class="h5list-item" v-if="incomeStatus == 3 || incomeStatus == 5">
              {{ $t("myincome.desc17") }}
              <div>{{ common.formatDate(item.sendTime) }}</div>
            </div>
            <div class="h5list-item" v-if="incomeStatus == 3">
              {{ $t("myincome.desc18") }}
              <div>
                {{
                  item.receiveTime > 0
                  ? common.formatDate(item.receiveTime)
                  : "--"
                }}
              </div>
            </div>
            <div class="h5list-item" v-if="incomeStatus == 2">
              {{ $t("myincome.desc17") }}
              <div>
                {{
                  item.earningDate > 0
                  ? common.formatDate(item.earningDate)
                  : "--"
                }}
              </div>
            </div>
            <div class="h5list-item" v-if="incomeStatus == 2">
              {{ $t("myincome.desc44") }}
              <div>
                {{
                  item.sendTime > 0 ? common.formatDate(item.sendTime) : "--"
                }}
              </div>
            </div>
            <div class="h5list-item">
              {{ $t("myincome.desc14") }}
              <div>{{ dealiIncomeType(item.incomeType) }}</div>
            </div>
            <div class="h5list-item">
              {{ $t("myincome.desc16") }}
              <div>{{ item.coinName }}</div>
            </div>
            <div class="h5list-item">
              {{ $t("myincome.desc19") }}
              <div>
                {{ common.cutXiaoNum1(item.amount) }}
                <P class="speed flex" v-if="item.activityAmount != 0 && item.incomeType==6 || item.activityAmount != 0 && item.incomeType==1">
                  <img src="../../public/images/new/speedicon.png" alt="" />
                  +{{ common.cutXiaoNum1(item.activityAmount) || 0 }} ({{ $t('market.desc206') }})
                </P>
              </div>
            </div>
            <div class="h5list-item">
              {{ $t("myincome.desc20") }}
              <div>{{ dealiIncomeStatus(item.incomeStatus) }}</div>
            </div>
            <div class="h5list-item" v-if="incomeStatus == 3">
              {{ $t("myincome.desc21") }}
              <div>{{ dealiAccountType(item.accountType) }}</div>
            </div>
            <div class="h5list-item" v-if="incomeStatus == 5">
              {{ $t("myincome.desc45") }}
              <div>{{ $t("myincome.desc46") }}</div>
            </div>
            <div class="h5list-item">
              {{ $t("myincome.desc22") }}
              <div class="green">{{ $t("record.desc15") }}</div>
            </div>
          </div>
        </van-cell>
      </van-list>
      <div class="no-data" v-if="showNull">
        <img src="../../public/images/no-data.png" alt="" />
        <span>{{ $t("record.desc13") }}</span>
      </div>
    </div>
    <!-- 领取 -->
    <el-dialog v-model="showReview">
      <div class="modal-info modal-info-review">
        <div class="info-title">
          <img src="../../public/images/new/closeicon.png" alt="" @click="showReview = false" />
        </div>
        <div class="info-icon">
          <img src="../../public/images/new/success.png" alt="" />
          <div>{{ $t("myincome.desc63") }}</div>
        </div>
        <div class="info-tips" v-html="$t('myincome.desc41')"></div>
        <div class="bt-list">
          <div class="bt" @click="showReview = false">
            {{ $t("myincome.desc42") }}
          </div>
          <div class="bt" @click="lookMoney()">{{ $t("myincome.desc43") }}</div>
        </div>
      </div>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog v-model="showInfo" @close="onClose">
      <div class="modal-info modal-info-detail" v-if="showInfo">
       
        <dsjl-detail :detailItem="detailItem" v-if="detailItem.incomeType == 4" />
        <tdss-detail :detailItem="detailItem" v-else @handlerClose="handlerClose" />
      </div>
    </el-dialog>
    <!-- 无内容详情 -->
    <el-dialog v-model="showInfo1">
      <div class="modal-info modal-info-detail">
        <div class="info-title">
          {{ $t("myincome.desc57") }}
          <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showInfo1 = false" />
        </div>

        <div class="modal-list">
          <div class="list-thead flex">
            <div>{{ $t("new.desc13") }}</div>
            <div>{{ $t("new.desc14") }}</div>
            <div>{{ $t("new.desc15") }}</div>
            <div>{{ $t("new.desc16") }}</div>
          </div>
          <div class="list-tbody flex">
            <div>{{ dealiIncomeType(showDetail.incomeType) }}</div>
            <div>
              {{ common.cutXiaoNum1(showDetail.amount) }}
              {{ showDetail.coinName }}
            </div>
            <div>
              {{
                showDetail.sendTime > 0
                ? common.formatDate(showDetail.sendTime)
                : "--"
              }}
            </div>
            <div>{{ dealiIncomeStatus(showDetail.incomeStatus) }}</div>
          </div>
        </div>
        <div class="modal-rules" v-if="showDetail.incomeType != 30">
          <div>{{ $t("new.desc17") }}</div>
          <div>{{ $t("new.desc18") }}</div>
          <div>{{ $t("new.desc19") }}</div>
          <div>{{ $t("new.desc20") }}</div>
        </div>
      </div>
    </el-dialog>
    <van-popup v-model:show="startShow" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker v-model="startCurrentTime" :confirm-button-text="$t('home.desc20')"
        :cancel-button-text="$t('home.desc45')" type="date" @confirm="confirmStartFn()" @cancel="startShow = false" />
    </van-popup>
    <van-popup v-model:show="endShow" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker v-model="endCurrentTime" type="date" :confirm-button-text="$t('home.desc20')"
        :cancel-button-text="$t('home.desc45')" @confirm="confirmEndFn()" @cancel="endShow = false" />
    </van-popup>
  </div>
</template>

<script>
import clipboard from "@/utils/libs/clipboard";
import tdssDetail from "./components/tdss-detail";
import dsjlDetail from "./components/dsjl-detail";
import store from "@/store";
import bus from "@/utils/bus";
export default {
  data() {
    return {
      showOverview: false,
      showInfo1: false,
      startCurrentTime: new Date(),
      endCurrentTime: new Date(),
      startShow: false,
      endShow: false,
      info: {},
      value1: "",
      iconList: [],
      coinId: "",
      incomeStatus: 3,
      versionType: 1,
      incomeType: "",
      startTime: "",
      endTime: "",
      page: 1,
      pageSize: 10,
      list: [],
      showNull: false,
      totals: 0,
      showInfo: false,
      modalInfo: {},
      loading: false,
      finished: false,
      showReview: false,
      statisticsInfo: {},
      incomeTodoInfo: {},
      isReviewIng: false,
      detailItem: {},
      todoAmountTotal: 0,
      showDetail: {},
    };
  },
  components: {
    tdssDetail,
    dsjlDetail,
  },
  mounted() {
    // this.$post(this.URL.assets.view, {
    //   relationCoinId: this.$route.query.id,
    //   accountType: 1
    // }).then(res => {
    //   if (res.code == 0) {
    //     this.info = res.data;
    //   } else {
    //     this.$message.error(res.message)
    //   }
    // })
    // this.getlist();
    this.init();
    bus.on("hadlerSuccess", (e) => {
      this.showReview = true;
    });
  },
  methods: {
    onClose() {
 
      this.showInfo=false;
    },
    handlerClose() {
      this.showInfo=false;
    },
    handlerOverview() {
      this.showOverview
        ? (this.showOverview = false)
        : (this.showOverview = true);
    },
    init() {
      this.getCoinList();
      this.getStatistics();
      this.getIncomeTodo();
      if (window.screen.width > 767) {
        this.getlist();
      }
    },
    dealiIncomeType(type) {
      // 1-土地收益 2-土地税收  3-星球推荐奖 4-代数奖励
      if (type == 1 || type == 6) {
        return this.$t("myincome.desc2");
      } else if (type == 2) {
        return this.$t("myincome.desc30");
      } else if (type == 3) {
        return this.$t("myincome.desc31");
      } else if (type == 11 || type == 16) {
        return this.$t("myincome.desc64");
      } else if (type == 12) {
        return this.$t("myincome.desc65");
      } else if (type == 13) {
        return this.$t("myincome.desc66");
      } else if (type == 14) {
        return this.$t("myincome.desc67");
      } else if (type == 20) {
        return this.$t("myincome.desc68");
      } else if (type == 30) {
        return this.$t("market.desc161");
      } else if (type == 40) {
        return this.$t("market.desc207")+' '+'DU';
      }
      else if (type == 41) {
        return this.$t("market.desc219");
      }else if (type == 42) {
        return this.$t("market.desc207")+' '+'RC';
      } else {
        return this.$t("myincome.desc32");
      }
    },
    dealiIncomeStatus(type) {
      // 状态： 2- 待领取 3-已领取 5-已损失
      if (type == 2) {
        return this.$t("myincome.desc35");
      } else if (type == 3) {
        return this.$t("myincome.desc36");
      } else {
        return this.$t("myincome.desc37");
      }
    },
    dealiAccountType(type) {
      // 发放账户 1-投资账户 2-奖金账户
      if (type == 1) {
        return this.$t("myincome.desc38");
      } else {
        return this.$t("myincome.desc39");
      }
    },
    exChangeType(i) {
      this.incomeStatus = i;
      this.handlerSearch();
    },
    getCoinList() {
      this.$post(this.URL.assets.coinList, {
        accountType: 2,
      }).then((res) => {
        if(res.code == 0) {
          this.iconList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getStatistics() {
      this.$post(this.URL.myincome.statistics, {}).then((res) => {
        if (res.code == 0) {
          this.statisticsInfo = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getIncomeTodo() {
      this.$post(this.URL.myincome.incomeTodo, {}).then((res) => {
        if (res.code == 0) {
          this.incomeTodoInfo = res.data;
          this.todoAmountTotal =
            Number(res.data.landInComeDu) +
            Number(res.data.landInComeRc) +
            Number(res.data.landTaxationDu) +
            Number(res.data.algebraRewardRc) +
            Number(res.data.recommendDu) +
            Number(res.data.landInComeDaot) +
            Number(res.data.landTaxationDaot) +
            Number(res.data.integralDp);
          this.incomeTodoInfo.hours = this.getInervalHour(
            this.incomeTodoInfo.lastGetTime,
            this.gettime()
          );
        } else {
          this.$message.error(res.message);
        }
      });
    },
    gettime() {
      return new Date(
        new Date().getTime() +
        (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000
      );
    },
    getInervalHour(startDate, endDate) {
      var ms = endDate.getTime() - startDate * 1000;
      if (ms < 0) return 0;
      return Math.floor(ms / 1000 / 60 / 60);
    },
    //一键领取收益
    handelReview() {
      let _this = this;
      // 领取收益
      if (this.todoAmountTotal <= 0) return;
      if (this.isReviewIng) return;
      this.isReviewIng = true;
      this.$post(this.URL.myincome.withdraw, {}).then((res) => {
        this.isReviewIng = false;
        if (res.code == 0) {
          this.getIncomeTodo();
          this.exChangeType(this.incomeStatus)
          setTimeout(() => {
            _this.$store.dispatch("togetherReceiveAfter");
          }, 1000);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    lookMoney() {
      this.showReview = false;
      this.$emit("lookMoney", 1);
    },
    onLoad() {
      setTimeout(() => {
        let data = {
          coinId: this.coinId,
          versionType: this.versionType,
          incomeStatus: this.incomeStatus, // 收益状态 0 待领取 1 已领取
          incomeType: this.incomeType, // 收益类型 1-土地收益 2-土地税收  3-星球推荐奖 4-代数奖励
          page: this.page,
          pageSize: this.pageSize,
        };

        if (this.startTime) {
          data.beginDate = this.startTime + " 00:00:00";
        }
        if (this.endTime) {
          data.endDate = this.endTime + " 23:59:59";
        }
        this.$post(this.URL.myincome.recordList, data).then((res) => {
          if (res.code == 0) {
            this.showNull = false;
            if (this.page == 1) {
              this.list = res.data.list;
            } else {
              this.list = this.list.concat(res.data.list);
            }
            this.page++;
            this.loading = false;
            if (this.list.length == 0) {
              this.showNull = true;
            }
            if (this.list.length >= parseFloat(res.data.page.count)) {
              this.finished = true;
            }
          } else {
            this.$message.error(res.message);
          }
        });
      }, 500);
    },
    onChage(val) {
      this.page = 1;
      this.loading = true;
      this.finished = false;
      this.list = [];
      this.showNull = false;
      this.onLoad();
    },
    confirmStartFn() {
      this.startTime = this.newDate(this.startCurrentTime);
      this.startShow = false;
      if (this.endTime) {
        this.page = 1;
        this.loading = true;
        this.finished = false;
        this.list = [];
        this.showNull = false;
        this.onLoad();
      }
    },
    confirmEndFn() {
      this.endTime = this.newDate(this.endCurrentTime);
      this.endShow = false;
      if (this.startTime) {
        this.page = 1;
        this.loading = true;
        this.finished = false;
        this.list = [];
        this.onLoad();
      }
    },
    handlerInfo(item) {
      if (
        item.incomeType == 11 ||
        item.incomeType == 13 ||
        item.incomeType == 12 ||
        item.incomeType == 14 ||
        item.incomeType == 16 ||
        item.incomeType == 20 ||
        item.incomeType == 30 ||
        item.incomeType == 41 
      ) {
        this.showDetail = item;
        this.showInfo1 = true;
        return;
      }
      this.detailItem = item;
      this.showInfo = true;
    },
    handlerInfoH5(item) {
      if (
        item.incomeType == 11 ||
        item.incomeType == 13 ||
        item.incomeType == 12 ||
        item.incomeType == 14 ||
        item.incomeType == 16 ||
        item.incomeType == 20 ||
        item.incomeType == 30 ||
        item.incomeType == 41 
      ) {
        this.showDetail = item;
        this.showInfo1 = true;
        return;
      }
      // 跳转详情
      this.$router.push({
        path: item.incomeType == 4 ? "/dsjl-detail" : "/tdss-detail",
        query: {
          sn: item.sn,
          incomeType: item.incomeType,
        },
      });
    },
    handlerSearch() {
      this.page = 1;
      this.totals = 0;
      this.showNull = false;
      this.getlist();
    },
    handlerReset() {
      this.page = 1;
      this.value1 = "";
      this.startTime = "";
      this.endTime = "";
      this.coinId = "";
      this.incomeType = "";
      this.totals = 0;
      this.showNull = false;
      this.getlist();
    },
    handlerCurrent(val) {
      this.page = val;
      this.getlist();
    },
    getlist() {
      let data = {
        coinId: this.coinId,
        versionType: this.versionType,
        incomeStatus: this.incomeStatus, // 状态： 2- 待领取 3-已领取 5-已损失
        incomeType: this.incomeType, // 收益类型 1-土地收益 2-土地税收  3-星球推荐奖 4-代数奖励
        page: this.page,
        pageSize: this.pageSize,
      };
      if (this.startTime) {
        data.beginDate = this.startTime + " 00:00:00";
      }
      if (this.endTime) {
        data.endDate = this.endTime + " 23:59:59";
      }
      this.$post(this.URL.myincome.recordList, data).then((res) => {
        if (res.code == 0) {
          this.list = res.data.list;
          this.totals = parseFloat(res.data.page.count);
          if (this.list.length == 0) {
            this.showNull = true;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    dateSelect(arr) {
      if (arr != null) {
        this.startTime = this.newDate(arr[0]);
        this.endTime = this.newDate(arr[1]);
      } else {
        this.startTime = "";
        this.endTime = "";
      }
    },
    newDate(time) {
      // 根据时间查询
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    copy(e, text) {
      clipboard(e, text);
      this.$message.success(this.$t("planet.desc61"));
    },
  },
};
</script>

<style lang="less" scoped>
.green {
  color: #c9fa5b !important;
}

.myincome {
  .myincome-top {
    margin-bottom: 32px;

    .top-info {
      position: relative;
      z-index: 1;
      padding: 28px 32px 32px;
      background: #181818;
      border-radius: 12px;
    }

    .mt-til,
    .mt-til-h5 {
      display: flex;
      align-items: baseline;
      font-family: Bai Jamjuree;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      color: #fff;
      margin-bottom: 24px;

      span {
        display: block;
        font-family: Bai Jamjuree;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 0.6);
        margin-left: 12px;
      }
    }

    .h5top-name {
      display: flex;
      font-size: 24px;
      color: #ffffff;
      padding: 20px 0 0;
      justify-content: space-between;
      line-height: 24px;

      p {
        font-size: 18px;
        color: #a0a0a0;
        line-height: 16px;
        cursor: pointer;

        img {
          width: 12px;
          height: 12px;
          margin: 3px 0 0 6px;
          transform: rotate(180deg);
          transition: 0.5s;
        }

        .img1 {
          transform: rotate(0deg);
          transition: 0.5s;
        }
      }
    }

    .mt-cen {
      display: flex;
      // justify-content: space-between;
      padding-right: 200px;
      flex-wrap: wrap;

      .mt-bt {
        display: none;
      }

      .mt-cen-item {
        flex: 0 0 25%;

        &:nth-child(-n + 8) {
          margin-bottom: 24px;
        }

        .mt-t {
          padding-left: 20px;
          font-family: Bai Jamjuree;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0px;
          text-align: left;
          color: #fff;
          margin-bottom: 13px;
          position: relative;

          &::before {
            position: absolute;
            left: 0;
            top: 1px;
            display: block;
            content: "";
            width: 12px;
            height: 12px;
            background: #c9fa5b;
            transform: rotate(45deg);
          }

          img {
            width: 12px;
            height: 12px;
            margin: 1px 8px 0 4px;
          }
        }

        .mt-b {
          font-family: Bai Jamjuree;
          font-size: 24px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
          color: #fff;
        }

      }
    }

    .mt-bot {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      padding: 28px 32px 32px;
      background: #181818;
      border-radius: 12px;

      .mt-bot-item {
        width: 160px;
        margin-bottom: 20px;

        .mt-t {
          display: flex;
          align-items: center;
          font-family: Bai Jamjuree;
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0px;
          text-align: left;
          color: #a8a8a8;
          margin-bottom: 8px;
          position: relative;
          z-index: 1;
          .mt-t-name{
            flex: 0 0 90px;
          }
          .mt-t-tips {
            width: 16px;
            height: 16px;
            margin-top: 0;
            margin-left: 2px;
            background: url("../../public/images/asset/my-income/icon.png") no-repeat center;
            background-size: 75% 75%;
            position: relative;
            cursor: pointer;
            z-index: 1;

            span {
              position: absolute;
              top: 16px;
              left: 50%;
              transform: translateX(-50%);
              display: none;
              width: 255px;
              padding: 15px 10px 10px;
              font-family: Bai Jamjuree;
              font-size: 13px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
              color: #fff;
              background: url("../../public/images/asset/my-income/Union.png") no-repeat;
              background-size: 100% 100%;
            }

            &:hover {
              span {
                display: block;
              }
            }
          }
        }

        .mt-b {
          font-family: Bai Jamjuree;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          color: #fff;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          .mt-t-tips {
            // span {width: 180px;}
          }
        }
      }
    }

    .mt-bt {
      position: absolute;
      top: 70px;
      right: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Bai Jamjuree;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      color: #000;
      width: 173.45px;
      height: 48.68px;
      background: url("../../public/images/asset/my-income/bt.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;

      &.on {
        filter: grayscale(1);
        cursor: inherit;
      }
    }
  }

  .myincome-ex {
    width: 100%;
    display: flex;
    padding: 0;
    margin-bottom: 24px;
    border-bottom: 1px solid #eeeeee33;

    // overflow-x: scroll;
    // &::-webkit-scrollbar {
    //   width: 0px; //修改滚动条宽度
    // }
    .me-item {
      height: 41px;
      font-family: Bai Jamjuree;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      margin-right: 36px;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.6);
      white-space: nowrap;

      &.on {
        color: #fff;
        position: relative;

        &::before {
          display: block;
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 1px;
          background: #c9fa5b;
        }
      }
    }
  }

  .myincome-title {
    margin: 40px 0 20px;
    font-size: 20px;
    color: #ffffff;
    line-height: 20px;
  }

  .myincome-select {
    margin-bottom: 24px;
    justify-content: space-between;

    .select-time {
      flex: 0 0 500px;

      ::v-deep .el-date-editor {
        height: 40px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid rgba(255, 255, 255, 0.2) !important;

        .el-range-input {
          color: #fff;
        }

        .el-range-separator {
          color: #fff;
        }
      }

      ::v-deep .el-select {
        width: 160px;
        margin-left: 20px;

        .el-input__wrapper {
          background-color: rgba(255, 255, 255, 0);
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
          height: 40px;

          .el-input__inner {
            color: #fff;
          }
        }

        .is-focus {
          box-shadow: 0 0 0 1px rgba(210, 251, 113, 1) inset !important;
        }
      }
    }

    .select-btn {
      flex: 0 0 186px;
      justify-content: space-between;

      div {
        flex: 0 0 88px;
        height: 40px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        font-size: 16px;
        color: #c9fa5b;
        cursor: pointer;

        &:last-child {
          background: #c9fa5b;
          color: #000;
          border: 1px solid #c9fa5b;
        }
      }
    }
  }

  .myincome-table {
    margin-top: 24px;

    .table-thead {
      padding: 20px 24px;
      background: #181818;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);

      div {
        flex: 1;

        &:last-child {
          flex: 0 0 80px;
          text-align: right;
        }
      }
    }

    .table-tbody {
      .tbody-list {
        padding: 22px 24px;
        font-size: 13px;
        color: #bbbbbb;
        border-bottom: 1px solid #0e0e0e;

        &:hover {
          background: #181818;
        }

        div {
          flex: 1;

          &:last-child {
            flex: 0 0 80px;
            cursor: pointer;
            justify-content: flex-end;

            img {
              width: 10px;
              height: 12px;
              margin: 3px 0 0 4px;
            }
          }
        }

        .flexcenter {
          justify-content: left;
        }
      }
    }

    .no-data {
      width: 100%;
      padding: 130px 0;

      img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
      }

      span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a1a1a1;
      }
    }
  }

  .speed {
    width: max-content;
    margin-top: 12px;
    border-radius: 6px;
    background: rgba(201, 250, 91, 0.10);
    font-size: 13px;
    color: #C9FA5B;
    padding: 4px;
    line-height: 14px;

    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }

  .h5-list {
    display: none;
  }

  .planet-page {
    padding: 0;
    margin-top: 40px;

    ::v-deep .el-pagination {
      button {
        background-color: rgba(255, 255, 255, 0);
        color: #666666;
        font-size: 14px;
      }

      .el-pager {
        li {
          background: rgba(255, 255, 255, 0);
          font-size: 14px;
          color: #666666;
        }

        .is-active {
          background: #c9fa5b !important;
          border-radius: 4px !important;
          color: #000000 !important;
        }
      }
    }
  }

  .rr-h5-time-out {
    display: none;
  }
}

::v-deep .el-dialog {
  // width: 520px;
  width: fit-content !important;

  .modal-info {
    position: relative;
    padding: 40px;
    width: 520px;

    &-review {
      width: 416px;
    }

    &-detail {
      width: 1000px;
    }

    .modal-list {
      margin-top: 32px;

      .list-thead {
        padding: 20px;
        background: #232323;
        justify-content: space-between;

        div {
          flex: 0 0 25%;
          font-size: 13px;
          color: rgba(255, 255, 255, 0.6);

          &:last-child {
            text-align: right;
          }
        }
      }

      .list-tbody {
        padding: 20px;
        font-size: 13px;
        color: #ffffff;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);

        div {
          flex: 0 0 25%;

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    .modal-rules {
      margin-top: 20px;
      font-size: 13px;
      color: #e15757;
      line-height: 16px;
    }

    .info-title {
      position: relative;
      font-size: 20px;
      color: #fff;

      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .info-icon {
      margin: 16px 0;
      text-align: center;
      font-size: 18px;
      color: #ffffff;

      img {
        width: 64px;
        height: 64px;
        margin-bottom: 16px;
      }
    }

    .info-tips {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 32px;
      letter-spacing: 0.05em;

      span {
        color: #c9fa5b;
      }
    }

    .bt-list {
      display: flex;
      align-items: center;
      gap: 16px;

      .bt {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 52px;
        background: url("../../public/images/asset/my-income/bt-1.png") no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        cursor: pointer;

        &:last-child {
          color: #000;
          background: url("../../public/images/asset/my-income/bt-2.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .info-list {
      padding: 20px;
      border-radius: 8px;
      background: #242424;

      .list-item {
        margin-bottom: 16px;
        font-size: 16px;

        &:nth-child(6) {
          padding-top: 16px;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
        }

        div {
          flex: 0 0 30%;

          color: rgba(255, 255, 255, 0.6);
        }

        p {
          flex: 0 0 70%;
          text-align: right;
          color: #fff;
          justify-content: flex-end;

          img {
            width: 12px;
            height: 12px;
            margin: 4px 0 0 8px;
            cursor: pointer;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .myincome {
    .myincome-select {
      .select-time {
        flex: 0 0 440px;

        ::v-deep .el-date-editor {
          width: 260px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .myincome {
    .myincome-top {
      padding: 0;
      background: transparent;
      margin-bottom: 20px;

      .top-info {
        padding: 16px 12px;

      }

      .mt-cen {
        margin-bottom: 0 !important;
        padding: 16px 0 !important;
      }

      .mt-til {
        display: block;
        font-size: 16px;
        margin-bottom: 12px;

        span {
          font-size: 12px;
          width: 100%;
          margin-left: 0;
          margin-top: 8px;
        }
      }

      .mt-bt {
        display: none;
      }

      .h5top-name {
        font-size: 16px;
        line-height: 16px;

        p {
          font-size: 14px;

          img {
            margin: 1px 0 0 6px;
          }
        }
      }

      .mt-cen {
        background: #181818;
        flex-wrap: wrap;
        padding: 20px;
        gap: 0;
        margin-bottom: 20px;
        border-radius: 12px;

        .mt-cen-item {
          flex: 0 0 50%;
          margin: 0;
          padding: 0;

          &:nth-child(-n + 8) {
            margin-bottom: 16px;
          }

          .mt-t {
            font-size: 12px;
          }

          .mt-b {
            font-size: 18px;
          }
        }

        .mt-bt {
          margin: 0 auto;
          position: inherit;
          top: inherit;
          right: inherit;
          width: 311px;
          height: 40px;
          display: flex;
          background: url("../../public/images/asset/my-income/bt-3.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      .mt-til-h5 {
        display: block;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 500;
      }

      .mt-bot {
        display: flex;
        flex-wrap: wrap;
        background: #181818;
        border-radius: 12px;
        padding: 20px;
        gap: 20px;
        flex-wrap: wrap;

        .mt-bot-item {
          width: calc(50% - 10px);
          margin: 0;
        }
      }
    }

    .myincome-ex {
      margin-bottom: 18px;
      padding: 0;

      .me-item {
        font-size: 14px;
        height: 30px;
      }
    }

    .myincome-title {
      margin: 0 0 16px;
      font-size: 16px;
    }

    .myincome-select {
      display: none;
    }

    .myincome-btn {
      margin: 24px 0 0;
      display: flex;
      justify-content: space-between;

      div {
        flex: 0 0 48%;
        height: 36px;

        font-size: 16px;
        background: url("../../public/images/new/btnbg17.png") center no-repeat;
        background-size: 100% 100%;
        color: #fff;
      }

      .btn1 {
        background: url("../../public/images/new/btnbg16.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }

    .myincome-table {
      display: none;
    }

    .rr-h5-time-out {
      display: block;
    }

    .rr-h5-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11px;
      height: 48px;
      padding: 0 44px 0 10px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;

      &-out {
        display: block;
        padding: 0;
        animation-delay: 0.3s;
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 18px;
      }

      span {
        display: block;
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-transform: capitalize;
        color: #ffffff;
        padding: 0 18px;
        width: 80px;
        text-align: center;
      }

      input {
        width: 100%;
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-transform: capitalize;
        color: #ffffff;

        &::placeholder {
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          text-transform: capitalize;
          color: rgba(255, 255, 255, 0.3);
        }
      }

      .item {
        position: relative;

        div {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 22;
          width: 100%;
          height: 100%;
        }
      }
    }

    .rr-h5-select {
      ::v-deep .el-select {
        width: 100%;
        margin-bottom: 11px;

        .el-input__wrapper {
          background-color: rgba(255, 255, 255, 0);
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
          height: 40px;

          .el-input__inner {
            color: #fff;
          }
        }

        .is-focus {
          box-shadow: 0 0 0 1px rgba(210, 251, 113, 1) inset !important;
        }
      }
    }

    .h5-list {
      margin-top: 16px;
      display: block;
      padding: 0;
      border-radius: 8px;
      background: none;

      ::v-deep .van-list {
        .van-cell {
          background: #181818;
          padding: 16px;
          margin-bottom: 10px;
          border-radius: 8px;

          &::after {
            left: 0;
            border-bottom: none;
          }
        }

        .h5list-con {
          .h5list-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 14px;
            color: rgba(255, 255, 255, 0.6);
            font-size: 13px;
            font-weight: 400;

            &:last-child {
              padding-bottom: 0;
            }

            div {
              font-size: 13px;
              font-weight: 500;
              color: #fff;
              text-align: right;
              .speed{
                margin-top:10px;
              }
            }
          }
        }
      }

      .no-data {
        width: 100%;
        padding: 40px 0;

        img {
          display: block;
          width: 120px;
          height: 120px;
          margin: 0 auto 16px;
        }

        span {
          display: block;
          margin: 0 auto;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #a1a1a1;
        }
      }
    }

    .planet-page {
      display: none;
    }
  }

  ::v-deep .el-dialog {
    .modal-info-detail {
      width: calc(100vw - 8px);
    }

    .modal-info {
      padding: 16px;

      .modal-list {
        .list-thead {
          padding: 12px;
        }

        .list-tbody {
          padding: 12px;
        }
      }

      &-review {
        width: 327px;
      }

      .info-icon {
        margin: 16px 0;
        font-size: 16px;
      }

      .info-list {
        padding: 12px;

        .list-item {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
    }
  }
}</style>
