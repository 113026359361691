<template>
  <el-dialog v-model="_show">
    <div class="popup-con">
      <div class="head">
        <div class="title">{{ $t("assetbag.desc45") }}</div>
        <div class="close" @click="_show = false" />
      </div>
      <div class="popup-scroll">
        <div class="con">
          <div class="list">
            <div class="item">
              <div class="name">{{ $t("assetbag.desc3") }}</div>
              <div class="item-right">
                <div class="val">{{ info.name }}</div>
              </div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc15") }}</div>
              <div class="item-right">
                <div class="val">
                  {{ common.cutXiaoNum1(info.releaseAmount) }} {{ info.releaseCoinName }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc24") }}</div>
              <div class="item-right">
                <div class="val">
                  {{ common.cutXiaoNum1(info.fillAmount) }} {{ info.fillCoinName }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc46") }}</div>
              <div class="item-right">
                <div class="val">
                  {{ common.cutXiaoNum1(info.canFillAmount) }} {{ info.fillCoinName }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc25") }}</div>
              <div class="item-right">
                <div class="val">
                  {{
                    common.numToFixed(
                      common.getValueMultip(info.fillRate, 100),
                      2
                    )
                  }}%
                </div>
              </div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc26") }}</div>
              <div class="item-right">
                <div class="val">
                  {{ common.getValueMultip(info.releaseRate, 100) }}%
                </div>
              </div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc40") }}</div>
              <div class="item-right">
                <div class="val">
                  {{
                    Number(info.fillAccountType) === 1
                      ? $t("new.desc7")
                      : $t("new.desc8")
                  }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc41") }}</div>
              <div class="item-right">
                <div class="val balance">
                  {{ common.cutXiaoNum1(info.balance) }} {{ info.fillCoinName }}
                </div>
              </div>
            </div>
          </div>
          <div class="inp-box">
            <div class="title">{{ $t("assetbag.desc49") }}</div>
            <div class="inp-con">
              <input class="inp" :value="amount" readonly />
              <span>{{ info.fillCoinName }}</span>
            </div>
            <div class="item">
              <div class="name">
                {{
                  $t("assetbag.desc54", {
                    n1: backfillCalInfo.fillAmount,
                    n2: backfillCalInfo.fillCoinName,
                  })
                }}
              </div>
              <div class="val">
                {{
                  $t("assetbag.desc55", {
                    n: common.numToFixed(
                      common.getValueMultip(backfillCalInfo.fillRate, 100),
                      2
                    ),
                  })
                }}%
              </div>
            </div>
            <div class="item">
              <div class="name">
                {{ $t("assetbag.desc56") }}
              </div>
              <div class="val">
                {{ backfillCalInfo.releaseDay }}
                {{ backfillCalInfo?.releaseCoinName }}
              </div>
            </div>
          </div>
        </div>
        <div class="foot">
          <div class="btn-con">
            <div class="btn cancel" @click="_show = false">
              {{ $t("assetbag.desc42") }}
            </div>
            <div class="btn confirm" @click="handlePsdShow" v-if="amount>0">
              {{ $t("assetbag.desc119") }} 
            </div>
            <div class="btn confirm grey"  v-else>
              {{ $t("assetbag.desc119") }} 
            </div>
          </div>
          <div class="tip">
            <div class="name">{{ $t("assetbag.desc51") }}</div>
            <p>{{ $t("assetbag.desc52") }}</p>
            <p>{{ $t("assetbag.desc53") }}</p>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <PsdPopup
    v-if="psdShow"
    v-model:show="psdShow"
    v-model:loading="loading"
    ref="isinout"
    @confirm="handleConfirm"
  />
  <SuccFailToast
    v-model:show="succFailShow"
    :title="$t('assetbag.desc90')"
    :content="
      $t('assetbag.desc91', {
        n: `${backfillCalInfo.fillAmount} ${backfillCalInfo.fillCoinName}`,
      })
    "
  />
</template>
<script>
export default {
  emits: ["succ", "update:show"],
  props: {
    show: Boolean,
    info: Object,
  },
  data() {
    return {
      psdShow: false,
      loading: false,
      succFailShow: false,
      backfillCalInfo: {
        fillCoinName: "",
        fillAmount: 0,
        fillRate: 0,
        releaseDay: 0,
        releaseCoinName: "",
        timer: null,
        isref:false
      },
    };
  },
  computed: {
    amount() {
      if (Number(this.info.balance) >= Number(this.info.canFillAmount)) {
        return Number(this.info.canFillAmount);
      } else {
        return Number(this.info.balance);
      }
    },

    _show: {
      get() {
        return this.show;
      },
      set(e) {
        this.$emit("update:show", e);
      },
    },
  },
  watch: {
    amount: {
      handler() {
        this.handleBackfillBagCal();
      },
      immediate: true,
    },
  },
  methods: {
    handleBackfillBagCal() {
      if (!this.amount) {
        return;
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.$post(this.URL.assetbag.backfillBagCal, {
          id: this.info.id,
          amount: this.amount,
        }).then((res) => {
          if (res.code !== 0) return;
          this.backfillCalInfo = res.data;
        });
      }, 400);
    },

    handlePsdShow() {
      if (!this.amount) {
        this.$message(this.$t("assetbag.desc50"));
        return;
      }
      if (this.loading) return;
      this.psdShow = true;
    },

    handleConfirm(password) {
     
      if(this.loading){
        return;
      }
      this.loading = true;
      this.$post(this.URL.assetbag.backfillBag, {
        id: this.info.id,
        amount: this.amount,
        password,
      }).then((res) => {
       
        if (res.code == 0) {
          this.succFailShow = true;
          this.psdShow = false;
          this._show = false;
          this.amount = "";
          this.$emit("succ");
        } else {
          this.loading = false;
          this.$refs.isinout.sendpsd();
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.popup-con {
  width: 520px;
  border-radius: 12px;
  background: #181818;
  padding: 40px 0;
  box-sizing: border-box;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .popup-scroll {
    padding: 0 40px;
    max-height: 85vh;
    overflow-y: auto;
  }

  .head {
    margin-bottom: 24px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .title {
      min-width: 0;
      flex: 1;
      color: #fff;
      //font-family: Bai Jamjuree;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .close {
      margin-left: 10px;
      flex: 0 0 auto;
      width: 24px;
      height: 24px;
      background: url("../../../../public/images/icon_close.png") no-repeat
        center;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .con {
    .list {
      border-radius: 8px;
      background: #242424;
      display: flex;
      flex-direction: column;
      padding: 20px;
      box-sizing: border-box;
    }
    .item {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      &:first-child {
        margin-top: 0;
      }
      .item-right {
        min-width: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .name {
        margin-right: 10px;
        color: rgba(255, 255, 255, 0.6);
        //font-family: Bai Jamjuree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        text-transform: capitalize;
      }
      .val {
        color: #fff;
        text-align: right;
        //font-family: Bai Jamjuree;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1em;
        text-transform: capitalize;
        text-align: right;
        &.balance {
          color: #c9fa5b;
          font-size: 16px;
        }
      }
      span {
        margin-top: 8px;
        color: rgba(255, 255, 255, 0.6);
        text-align: right;
        //font-family: Bai Jamjuree;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        text-transform: capitalize;
      }
    }
    .inp-box {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      .title {
        margin-bottom: 12px;
        color: #fff;
        //font-family: Bai Jamjuree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
      .inp-con {
        display: flex;
        border-radius: 4px;
        border: 1px solid #393939;
        height: 52px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .inp {
          min-width: 0;
          flex: 1;
          height: 100%;
          padding: 0 16px;
          display: flex;
          align-items: center;
          color: #fff;
          &::placeholder {
            color: rgba(255, 255, 255, 0.4);
          }
        }
        span {
          flex: 0 0 auto;
          margin-right: 16px;
          color: #fff;
          text-align: right;
          //font-family: Bai Jamjuree;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
  }
  .foot {
    .btn-con {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      .btn {
        width: 210px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        text-align: center;
        //font-family: Bai Jamjuree;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        cursor: pointer;
        &.cancel {
          color: #fff;
          background-image: url("../../../../public/images/asset/assetbag/pupup_btn1_bg.png");
        }
        &.confirm {
          color: #000;
          background-image: url("../../../../public/images/asset/assetbag/pupup_btn2_bg.png");
        }
      }
    }
    .tip {
      margin-top: 20px;

      .name {
        color: #fff;
        //font-family: Bai Jamjuree;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 123.077% */
        text-transform: capitalize;
      }
      p {
        color: rgba(255, 255, 255, 0.8);
        //font-family: Bai Jamjuree;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: capitalize;
      }
    }
  }
}
@media (max-width: 767px) {
  .popup-con {
    width: 100%;
    padding: 20px 0;
    .popup-scroll {
      padding: 0 20px;
    }
    .head {
      margin-bottom: 20px;
      padding: 0 20px;
      .title {
        font-size: 18px;
      }
    }
    .con {
      .list {
        border-radius: 6px;
        padding: 16px;
      }
      .item {
        margin-top: 16px;
        .name,
        .val {
          font-size: 13px;
        }
        .val {
          &.balance {
            font-size: 13px;
          }
        }
      }
    }
    .foot {
      .btn-con {
        margin-top: 24px;
        .btn {
          max-width: 48%;
          width: 137px;
          height: 48px;
          font-size: 14px;
        }
      }
      .tip {
        margin-top: 16px;
        font-size: 13px;
      }
    }
  }
}
</style>
