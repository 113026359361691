<template>
  <div class="invest">
    <div class="invest-overview flex">
      <div class="overview-left">
        <div class="left-name flex">
          <img src="../../public/images/new/assetsicon.png" alt="" />
          {{ investTitle }}
        </div>
        <div class="left-price">
          ${{ common.cutXiaoNum2(allAccountTotalUSDTAmount) }}
        </div>
        <!-- <div class="left-nav flex" v-if="act==3">
          <div v-if="act == 1" class="flexcenter" @click="jump_link('/recharge', '')">{{ $t('asset.desc103') }}</div>
          <div v-if="act == 2" class="flexcenter" @click="jump_link('/withdraw', '')">{{ $t('asset.desc1') }}</div>
        </div> -->
      </div>
      <div class="overview-right">
        <div class="or-left">
          <div class="or-l-title">
            {{ $t("asset.desc112") }}
            <div class="or-l-tips">
              <span>{{ $t("asset.desc113") }}</span>
            </div>
          </div>
          <div class="or-l-list">
            <div class="or-l-item" v-for="item of datainfoList" :key="item.id">
              {{ item.coinName }}
              <span>{{ dealPercent(item.balanceUSDTAmount, 2) }}</span>
            </div>
          </div>
        </div>
        <div class="myChart">
          <div class="myChart-box">
            <div class="mb-title">{{ $t("asset.desc104") }}</div>
            <div class="mb-amount">
              ≈${{ common.cutXiaoNum2(allAccountTotalUSDTAmount) }}
            </div>
          </div>
          <div id="myChart"></div>
        </div>
      </div>
    </div>
    <div class="overview-table">
      <div class="table-name">{{ $t("asset.desc105") }}</div>
      <div class="table-thead flex">
        <div>{{ $t("quotes.desc2") }}</div>
        <div>{{ $t("asset.desc106") }}</div>
        <div>{{ $t("asset.desc107") }}</div>
        <div>{{ $t("asset.desc108") }}</div>
        <div>{{ $t("asset.desc109") }}</div>
        <div>{{ $t("quotes.desc6") }}</div>
      </div>
      <div class="table-tbody flex" v-for="item of datainfoList" :key="item.id">
        <div class="tbody-info flex">
          <p class="flex">
            <img :src="item.coinIcon" alt="" /> {{ item.coinName }}
          </p>
        </div>
        <div class="tbody-info flex">
          <div>{{ $t("asset.desc106") }}</div>
          <P>{{ common.cutXiaoNum2(item.totalAmount) }}</P>
        </div>
        <div class="tbody-info flex">
          <div>{{ $t("asset.desc107") }}</div>
          <P>{{ common.cutXiaoNum2(item.amount - item.frozenAmount) }}</P>
        </div>
        <div class="tbody-info flex">
          <div>{{ $t("asset.desc108") }}</div>
          <P>{{ common.cutXiaoNum2(item.frozenAmount) }}</P>
        </div>
        <div class="tbody-info flex">
          <div>{{ $t("asset.desc109") }}</div>
          <P>{{ common.cutXiaoNum2(item.totalUSDTAmount) }}</P>
          <!-- {{ item.assetTransferStatus }} {{ item.statBtn }} -->
        </div>
        <div class="tbody-info flex">
          <ul class="flex">
            <!-- <li
              class="info-btn btnbg flexcenter"
              
              v-if="item.withdrawStatus && act == 3"
              @click="$router.push('/quotes')"
            >
              {{ $t("asset.desc136") }}
            </li>  -->
            <li
              class="info-btn btnbg flexcenter"
              v-if="item.withdrawStatus && act == 2"
              @click="jump_link('/withdraw', item.coinId)"
            >
              {{ $t("asset.desc1") }}
            </li>
            <template v-if="item.assetTransferStatus">
              <li
                class="info-btn btnbg flexcenter"
                v-if="item.statBtn === 1"
                @click="handleAssetTransferStatus(item)"
              >
                {{ $t("new.desc12") }}
              </li>
              <li
                class="info-btn btnbg flexcenter disabale"
                v-if="item.statBtn === 0"
                @click="handleAssetTransferStatus1"
              >
                {{ $t("new.desc12") }}
              </li>
            </template>

            <li
              class="info-btn btnbg flexcenter"
              v-if="item.rechargeStatus && act == 1"
              @click="jump_link('/recharge', item.coinId)"
            >
              {{ $t("asset.desc2") }}
              <p class="flexcenter" v-if="item.coinName == 'DU' && isactive">{{ $t('asset.desc145',{n1:activiteInfo.ratio * 100}) }}</p>
            </li>

            <li
              class="info-btn btnbg flexcenter"
              v-if="item.bagTransferStatus && act == 1"
              @click="handleBagTransfer(item)"
            >
              {{ $t("common.desc6") }}
            </li>
            <li
              class="info-btn flexcenter"
              v-if="act == 1 && item.linkCode === 3"
              @click="$emit('tab', 4)"
            >
              {{ $t("assetbag.desc134") }}
            </li>
            <li
              class="info-btn flexcenter"
              v-if="item.buyPlanetStatus"
              @click="$router.push('/node')"
            >
              {{ $t("asset.desc79") }}
            </li>
            <li
              class="info-btn flexcenter"
              @click="
                $router.push(`/record/detail?id=${item.coinId}&act=${act}`)
              "
            >
              {{ $t("asset.desc80") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {
      act: 1, // 1=投资账户,2=奖金账户,3=交易账户
      drawData: [],
      datainfo: null,
      datainfoList: [],
      allAccountTotalUSDTAmount: 0,
      allAmount: 0,
      investTitle: "",
      listTime: [],
      isactive:false,
      activiteInfo:{}
    };
  },
  mounted() {
    this.init(this.type);
    this.$post(this.URL.assets.rechargeConfig,{
      activityType:1
    }).then(res =>{
      if(res.code==0 && res.data != null){
        this.activiteInfo=res.data;
        this.isactive=true;
      
      }
    })
  },
  methods: {
    init(type) {
      this.act = type;
      if (type == 1) {
        this.investTitle = this.$t("asset.desc110");
      } else if (type == 3) {
        this.investTitle = this.$t("new.desc9");
      } else {
        this.investTitle = this.$t("asset.desc111");
      }
      this.$post(this.URL.assets.overview, {
        accountType: this.act,
      }).then((res) => {
        if (res.code == 0) {
          this.allAccountTotalUSDTAmount = res.data.allAccountTotalUSDTAmount;
        }
      });
      this.$post(this.URL.assets.transferList, {}).then((res) => {
        if (res.code == 0) {
          this.listTime = res.data;
          this.getinfo();
        }
      });
    },
    dealNum(num, n) {
      if (num == undefined) {
        return;
      }
      // let str = num.substring(0, num.indexOf(".") + 5);
      // return str;
      let numStr = num.toString();
      const flag = numStr.includes(".");

      let newNum;
      if (flag) {
        let length = numStr.split(".")[1].length;
        if (length > 4) {
          newNum = numStr.substring(0, numStr.indexOf(".") + 5);
        } else {
          newNum = numStr;
        }
      } else {
        newNum = numStr;
      }
      return newNum;
    },
    jump_link(url, id) {
      this.$router.push({
        path: url,
        query: {
          id: id,
          type: this.act,
        },
      });
    },
    hanlderTab(i) {
      this.act = i;
      this.datainfo = null;
      this.getinfo();
    },
    getinfo() {
      this.drawData = [];
      this.allAmount = 0;
      this.$post(this.URL.assets.info, {
        accountType: this.act,
      }).then((res) => {
        if (res.code == 0) {
          let pieList = [];
          this.datainfo = res.data;
          const list = res.data.list;
          this.datainfoList = list.map((item) => {
            pieList.push({
              value: item.balanceUSDTAmount,
              name: item.coinFullName,
            });
            let statBtn = 2; //0置灰 1高亮 2不显示
            let timeinfo = this.listTime.find(
              (timeItem) =>
                Number(timeItem.payCoinId) === Number(item.coinId) &&
                Number(timeItem.payAccountType) === Number(this.type)
            );
            if (timeinfo) {
              let todaylen = new Date().getTime(),
                statrlen = timeinfo.startTimes,
                endlen = timeinfo.endTimes;
              if (todaylen < statrlen || todaylen > endlen) {
                statBtn = 0;
              } else {
                statBtn = 1;
              }
            }
            return {
              statBtn,
              ...item,
            };
          });
          this.drawLine(pieList);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    dealPercent(num) {
      num = parseFloat(num, this.allAccountTotalUSDTAmount);
      if (num && num > 0) {
        let resul = (num / this.allAccountTotalUSDTAmount) * 100;
        return resul.toFixed(2) + "%";
      } else {
        return "0.00%";
      }
    },
    getPercent(a, b) {
      if (b <= 0) return "0.00%";
      let value = (a / b) * 100;
      let bit = 3;

      let str = value.toString();
      let strIndex = str.indexOf(".");
      if (strIndex === -1) return str + ".00%";
      str = str.substring(0, strIndex + bit);

      return str + "%";
    },
    drawLine(pieList) {
      const myChart = this.$echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        color: [
          "rgba(245, 196, 84, 1)",
          "rgba(2, 153, 254, 1)",
          "rgba(12, 189, 19, 0.8)",
          "rgba(228, 23, 12, 0.8)",
        ],
        series: [
          {
            name: "",
            type: "pie",
            hoverAnimation: true,
            avoidLabelOverlap: true,
            minAngle: 10, // 最小扇形角度
            center: ["50%", "50%"],
            radius: ["72%", "90%"],
            label: {
              show: false,
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#181818",
              borderWidth: 1,
              borderJoin: "round",
            },
            data: pieList,
          },
        ],
      });
    },
    dealValue(val) {
      let num = 0;
      for (let i = 0; i < this.drawData.length; i++) {
        const element = this.drawData[i];
        num += Number(element.value);
      }
      if (num > 0) {
        return ((val / num) * 100).toFixed(2) + "%";
      } else {
        return "0.00%";
      }
    },
    handelToast(message) {
      this.$message(message);
    },
    handleAssetTransferStatus(item) {
      this.$router.push(
        `/asset/transfer?coinId=${item.coinId}&type=${this.act}`
      );
    },
    handleAssetTransferStatus1() {
      this.$message(this.$t("asset.desc139"));
      return;
    },
    //资金包充值跳转
    handleBagTransfer(item) {
      this.$router.push(
        `/third/transfer?coinId=${item.coinId}&type=${this.act}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.invest {
  .invest-overview {
    padding: 40px;
    border-radius: 16px;
    background: #181818;

    .overview-left {
      flex: 1;
      overflow: hidden;

      .left-name {
        font-size: 20px;
        color: #ffffff;
        line-height: 20px;

        img {
          width: 18px;
          height: 18px;
          margin: 0 4px 0 0;
        }
      }

      .left-price {
        padding: 24px 0 32px;
        font-size: 44px;
        color: #c9fa5b;
      }

      .left-nav {
        div {
          flex: 0 0 160px;
          height: 44px;
          margin-right: 20px;
          background: url("../../public/images/new/assetsbg3.png") center
            no-repeat;
          background-size: 100% 100%;
          font-size: 16px;
          color: #000000;
          cursor: pointer;
          font-weight: 600;

          &:last-child {
            margin-right: 0;
          }

          // &:hover {
          //   background: url('../../public/images/new/assetsbg2.png') center no-repeat;
          //   background-size: 100% 100%;
          //   color: #000000;
          //   font-weight: 600;
          // }
        }

        .act {
          background: url("../../public/images/new/assetsbg2.png") center
            no-repeat;
          background-size: 100% 100%;
          color: #000000;
          font-weight: 600;
        }
      }
    }

    .overview-right {
      flex: 0 0 280px;
      display: flex;
      align-items: center;

      .or-left {
        width: fit-content;

        .or-l-title {
          display: flex;
          align-items: center;
          font-family: Bai Jamjuree;
          font-size: 18px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          color: #fff;
          margin-bottom: 24px;
          white-space: nowrap;

          .or-l-tips {
            width: 16px;
            height: 16px;
            margin-left: 8px;
            background: url("../../public/images/asset/my-income/icon.png")
              no-repeat center;
            background-size: 100% 100%;
            position: relative;
            cursor: pointer;
            z-index: 1;

            span {
              position: absolute;
              top: 18px;
              left: 50%;
              transform: translateX(-50%);
              display: none;
              width: 255px;
              padding: 15px 10px 10px;
              font-family: Bai Jamjuree;
              font-size: 13px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
              color: #fff;
              background: url("../../public/images/asset/my-income/Union.png")
                no-repeat;
              background-size: 100% 100%;
              opacity: 1;
              white-space: normal;
            }

            &:hover {
              span {
                display: block;
              }
            }
          }
        }

        .or-l-list {
          .or-l-item {
            display: flex;
            align-items: center;
            padding-left: 20px;
            position: relative;
            font-family: Bai Jamjuree;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 16px;
            white-space: nowrap;

            &::before {
              display: block;
              content: "";
              width: 12px;
              height: 12px;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              background: rgba(245, 196, 84, 1);
            }

            &:nth-child(2) {
              &::before {
                background: rgba(2, 153, 254, 1);
              }
            }

            &:nth-child(3) {
              &::before {
                background: rgba(12, 189, 19, 0.8);
              }
            }

            &:nth-child(4) {
              &::before {
                background: rgba(228, 23, 12, 0.8);
              }
            }

            span {
              margin-left: 6px;
              font-size: 16px;
              font-weight: 500;
              color: #fff;
            }
          }
        }
      }

      .myChart {
        width: 200px;
        height: 200px;
        position: relative;

        .myChart-box {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: fit-content;

          .mb-title {
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
            white-space: nowrap;
            margin-bottom: 6px;
          }

          .mb-amount {
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            text-align: center;
            white-space: nowrap;
          }
        }

        #myChart {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .overview-table {
    margin-top: 24px;
    padding: 40px;
    background: #181818;
    border-radius: 16px;

    .table-name {
      padding-bottom: 24px;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.8);
      border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    }

    .table-thead {
      padding: 32px 0 8px;

      div {
        flex: 0 0 170px;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.6);

        &:first-child {
          flex: 0 0 170px;
        }

        &:last-child {
          flex: 1;
          // text-align: right;
        }
      }
    }

    .table-tbody {
      .tbody-info {
        flex: 0 0 170px;
        font-size: 13px;
        padding: 10px 0;
        line-height: 36px;
        color: rgba(255, 255, 255, 0.6);

        ul {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          li {
            margin: 3px 0;
          }
        }

        p {
          color: #fff;

          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 16px;
          }
        }

        div {
          display: none;
        }

        &:first-child {
          flex: 0 0 170px;
        }

        &:last-child {
          flex: 1;
        }

        .info-btn {
          position: relative;
          flex: 0 0 96px;
          height: 36px;
          font-size: 14px;
          margin-right: 10px;
          color: #ffffff;
          box-sizing: border-box;
          cursor: pointer;
          background: url("../../public/images/new/btnbg29.png") center
            no-repeat;
          background-size: 100% 100%;

          &:last-child {
            margin-right: 0;
          }
          p{
            position: absolute;
            top: -22px;
            right: -54px;
            width: 104px;
            height: 20px;
            border-radius: 6px 6px 6px 0px;
            background: #EB4343;
            font-size: 10px;
            color: #FFFFFF;
          }
        }

        .btnbg {
          background: url("../../public/images/new/btnbg8.png") center no-repeat;
          background-size: 100% 100%;
          color: #181818;
          border: 0 solid #eee;
        }

        .disabale {
          background: url("../../public/images/asset/btn_disable.png") center
            no-repeat;
          background-size: 100% 100%;
          color: #181818;
          border: 0 solid #eee;
        }
      }
    }
  }
}

.grey {
  filter: grayscale(1) !important;
}

@media (max-width: 1200px) {
  .invest {
    .invest-overview {
      padding: 20px;

      .overview-left {
        .left-nav {
          div {
            flex: 0 0 32%;
            height: 36px;
            margin-right: 2%;
            font-size: 14px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .overview-table {
      padding: 20px;

      .table-thead {
        div {
          flex: 1;

          &:first-child {
            flex: 0 0 120px;
          }

          &:last-child {
            flex: 0 0 280px;
          }
        }
      }

      .table-tbody {
        .tbody-info {
          flex: 1;

          &:first-child {
            flex: 0 0 120px;
          }

          &:last-child {
            flex: 0 0 280px;

            ul {
              width: 100%;
            }

            .info-btn {
              flex: 0 0 88px;
              margin-right: 2%;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .invest {
    .overview-table {
      .table-thead {
        div {
          &:first-child {
            flex: 0 0 90px;
          }
        }
      }

      .table-tbody {
        .tbody-info {
          p {
            img {
              align-self: center;
              margin-right: 6px;
            }
          }

          &:first-child {
            flex: 0 0 90px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .invest {
    .invest-overview {
      display: block;
      padding: 20px 16px;
      background: #181818;
      position: relative;
      border-radius: 8px;

      .overview-left {
        flex: 0 0 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid #ffffff0f;
        margin-bottom: 16px;
        min-height: 130px;

        .left-name {
          font-size: 14px;
        }

        .left-price {
          padding: 16px 0;
          font-size: 24px;
        }

        .left-nav {
          div {
            flex: 0 0 32%;
            height: 36px;
            margin-right: 2%;
            font-size: 14px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .overview-right {
        .or-left {
          .or-l-title {
            font-size: 14px;
            margin-bottom: 16px;

            .or-l-tips {
              width: 14px;
              height: 14px;
              margin-left: 6px;
            }
          }

          .or-l-list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .or-l-item {
              flex: 1;
              padding-left: 16px;
              margin-bottom: 0;
              font-size: 12px;

              span {
                font-size: 12px;
              }
            }
          }
        }

        .myChart {
          position: absolute;
          top: 16px;
          right: 12px;
          width: 130px;
          height: 130px;

          .myChart-box {
            .mb-amount {
              font-size: 14px;
            }
          }
        }
      }
    }

    .overview-table {
      margin-top: 16px;
      padding: 0;
      border-radius: 8px;
      background: transparent;

      .table-name {
        display: none;
      }

      .table-thead {
        display: none;
      }

      .table-tbody {
        display: block;
        margin-bottom: 10px;
        // border-bottom: 1px solid rgba(255, 255, 255, 0.06);
        background: #181818;
        border-radius: 8px;
        padding: 16px;

        &:last-child {
          border-bottom: 0;
        }

        .tbody-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 0 16px 0;
          line-height: 14px;

          div {
            display: block;
          }

          p {
            display: flex;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }

          &:nth-child(1) {
            justify-content: flex-start;
            padding: 0 0 12px 0;
            margin-bottom: 16px;
            border-bottom: 1px solid #ffffff0f;
          }

          &:last-child {
            margin-bottom: 0;
            padding: 0;

            .info-btn {
              flex: 0 0 96px;
              height: 36px;
              margin-right: 0;
            }
          }

          ul {
            gap: 10px;
          }
        }
      }
    }
  }
}
</style>
