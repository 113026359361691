<template>
  <div class="detail">
    <div class="title">{{ $t('myincome.desc57') }}  
      <img src="../../../public/images/new/closeicon.png" alt="" @click="handlerClose" />
    </div>
    <div class="wrap">
      <div class="wrap-til">{{ $t('myincome.desc58') }}<span>{{ info.earningDate }}</span></div>
      <div v-if="detailItem.incomeType == 3 || detailItem.incomeType == 40"></div>
      <div class="wrap-til" v-else>{{ $t('myincome.desc62') }}<span>{{ info.investTotalAmount }}{{ info.investCoinName }}</span></div>
      <div class="wrap-til">{{ $t('myincome.desc59') }}<span class="on">{{ info.earningTotalAmount }}{{ info.earningCoinName }}</span></div>
    </div>
    <div class="myincome-table animate__animated animate__fadeInUp animate__delay-.6s">
      <div class="table-thead flex">
        <div>{{ $t('myincome.desc14') }}</div>
        <div>{{ $t('myincome.desc19') }}</div>
        <div>{{ $t('myincome.desc47') }}</div>
        <div>{{ $t('myincome.desc48') }}</div>
        <div>{{ $t('myincome.desc49') }}</div>
        <div v-if="detailItem.incomeType == 3 || detailItem.incomeType == 40">{{ $t('market.desc214') }}</div>
        <div v-else>{{ $t('myincome.desc50') }}</div>
        <div v-if="detailItem.incomeType == 3 || detailItem.incomeType == 40">{{ $t('market.desc215') }}</div>
        <div v-else>{{ $t('myincome.desc51') }}</div>
        <div>{{ $t('myincome.desc52') }}</div>
      </div>
      <div class="table-tbody">
        <div class="tbody-list flex" v-for="item in info.voList" :key="item.id">
          <div class="flexcenter">{{ dealiIncomeType(detailItem.incomeType) }}</div>
          <div><p class="flexcenter" :style="{height:item.activityAmount !=0 && detailItem.incomeType == 6 || item.activityAmount !=0 && detailItem.incomeType == 1?'inherit':'100%'}">{{ item.earningAmount }}{{ item.earningCoinName }}</p>
            <P class="speed flex" v-if="item.activityAmount !=0 && detailItem.incomeType==6 || item.activityAmount !=0 && detailItem.incomeType==1">
                  <img src="../../../public/images/new/speedicon.png" alt="" />
                  +{{ common.cutXiaoNum1(item.activityAmount) || 0 }} ({{ $t('market.desc206') }})
                </P>
          </div>
          <div class="flexcenter">{{ item.planetName }}</div>
          <div class="flexcenter">{{ item.coord }}</div>
          <div class="flexcenter">LV{{ item.landLevel }}{{ item.landLevelName }}</div>
          <div class="flexcenter" v-if="detailItem.incomeType == 3 || detailItem.incomeType == 40">{{ item.activityAmount }} {{ item.investCoinName }}</div>
          <div class="flexcenter" v-else>{{ item.investTotalAmount }}{{ item.investCoinName }}</div>
          <div class="flexcenter" v-if="detailItem.incomeType == 3 || detailItem.incomeType == 40">{{ common.cutXiaoNum1(item.activityRate * 100) || 0 }}% </div>
          <div v-else>
            <p class="flexcenter"  :style="{height:item.activityRate !=0 && detailItem.incomeType == 6 || item.activityRate !=0 && detailItem.incomeType == 1?'inherit':'100%'}">{{ common.getnum(item.earningRate * 100) + '%' }}</p>
            <P class="speed flex" v-if="item.activityRate !=0 && detailItem.incomeType==6 || item.activityRate !=0 && detailItem.incomeType == 1">
                  <img src="../../../public/images/new/speedicon.png" alt="" />
                  +{{ common.cutXiaoNum1(item.activityRate * 100) || 0 }}% ({{ $t('market.desc212') }})
                </P>
          </div>
          <div class="flexcenter">{{ dealiIncomeStatus(item.status) }}</div>
        </div>
      </div>
      <div class="no-data" v-if="showNull">
        <img src="../../../public/images/no-data.png" alt="">
        <span>{{ $t('record.desc13') }}</span>
      </div>
    </div>
    <div class="tips animate__animated animate__fadeInUp animate__delay-1s" v-if="detailItem.incomeType == 6">
      <p>{{ $t('market.desc209') }}</p>
      <p>{{ $t('market.desc210') }}</p>
      <p>{{ $t('market.desc211') }}</p>
    </div>
    <!-- <div class="tips animate__animated animate__fadeInUp animate__delay-1s" v-if="detailItem.incomeType == 3 || detailItem.incomeType == 40">
      <p>{{ $t('market.desc213') }}</p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from "@/store";
export default {
  computed: {
    ...mapState(['assetType']),
  },
  props: ['detailItem'],
  data() {
    return {
      info: {},
      showNull: false
    }
  },
  mounted() {
    this.handlerInfo(this.detailItem)
   
  },
  methods: {
    handlerClose() {
      this.$emit('handlerClose')
    },
    dealiIncomeType(type) { // 1-土地收益 2-土地税收  3-星球推荐奖 4-代数奖励
      if (type == 1 || type == 6) {
        return this.$t("myincome.desc2");
      } else if (type == 2) {
        return this.$t("myincome.desc30");
      } else if (type == 3) {
        return this.$t("myincome.desc31");
      } else if (type == 11 || type == 16) {
        return this.$t("myincome.desc64");
      } else if (type == 12) {
        return this.$t("myincome.desc65");
      } else if (type == 13) {
        return this.$t("myincome.desc66");
      } else if (type == 14) {
        return this.$t("myincome.desc67");
      } else if (type == 20) {
        return this.$t("myincome.desc68");
      }else if (type == 30) {
        return this.$t("market.desc161");
      } else if (type == 40) {
        return this.$t("market.desc207") + ' ' + 'DU';
      }
      else if (type == 41) {
        return this.$t("market.desc219");
      } else if (type == 42) {
        return this.$t("market.desc207") + ' ' + 'RC';
      }else {
        return this.$t("myincome.desc32");
      }
    },
    dealiIncomeStatus(type) { // 状态： 2- 待领取 3-已领取 5-已损失
      if (type == 2) {
        return this.$t('myincome.desc35')
      } else if (type == 3) {
        return this.$t('myincome.desc36')
      } else {
        return this.$t('myincome.desc37')
      }
    },
    handlerInfo(item) {
      let url = this.URL.myincome.detail
      if (item.incomeType == 4) url = this.URL.myincome.teamEarningDetail
      this.$post(url, {
        sn: item.sn
      }).then(res => {
        if (res.code == 0) {
          this.info = res.data;
          if (this.info.voList.length == 0) {
            this.showNull = true
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.detail {
  .title {
    position: relative;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
    img{
      position: absolute;
      top: 2px;
      right: 0;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .wrap {
    display: flex;
    align-items: center;

    .wrap-til {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-right: 40px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.6);

      span {
        color: #fff;
        &.on {
          color: #C9FA5B;
        }
      }
    }
  }

  .myincome-table {
    margin-top: 24px;

    .table-thead {
      padding: 20px 24px;
      background: #232323;
      font-size: 13px;
      color: rgba(255, 255, 255, .6);

      div {
        flex: 1;
        &:nth-child(2),&:nth-child(7){
            flex: 0 0 160px;
          }
        &:last-child {
          flex: 0 0 80px;
          text-align: right;

        }
      }
    }

    .table-tbody {
      max-height: 590px;
      overflow-y: scroll;

      &::-webkit-scrollbar {

        display: none;

      }

      .tbody-list {
        padding: 22px 24px;
        font-size: 13px;
        color: #BBBBBB;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);

        &:hover {
          background: #181818;
        }

        div {
          flex:1;
          width: 113.15px;
          justify-content: left;
          &:nth-child(2),&:nth-child(7){
            flex: 0 0 160px;
          }
          p{
            justify-content: left;
          }
          &:last-child {
            flex: 0 0 80px;
            cursor: pointer;
            justify-content: flex-end;
            text-align: right;
            img {
              width: 10px;
              height: 12px;
              margin: 3px 0 0 4px;
            }
          }
        }
      }
    }

    .no-data {
      width: 100%;
      padding: 100px 0;

      img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
      }

      span {
        display: block;
        margin: 0 auto;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #A1A1A1;
      }
    }
  }
  .tips{
    margin-top: 20px;
      font-size: 13px;
      color: #e15757;
      line-height: 16px;
  }
}

.speed {
    width: max-content;
    margin-top: 12px;
    border-radius: 6px;
    background: rgba(201, 250, 91, 0.10);
    font-size: 13px;
    color: #C9FA5B;
    padding: 4px;
    line-height: 14px;

    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
</style>
