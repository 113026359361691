<template>
  <div class="asset" :class="{maxtop:showActivity==0}">
    <h5Top />
    <div class="asset-wrap">
      <div class="asset-ex animate__animated animate__fadeInUp">
        <div class="ae-item" :class="{ on: type == 1 }" @click="exChangeInvestType(1)">
          {{ $t("asset.desc100") }}
        </div>
        <div class="ae-item" :class="{ on: type == 2 }" @click="exChangeInvestType(2)">
          {{ $t("asset.desc101") }}
        </div>
        <div class="ae-item" :class="{ on: type == 3 }" @click="exChangeInvestType(3)">
          {{ $t("new.desc9") }}
        </div>
        <div class="ae-item" :class="{ on: type == 5 }" @click="exChangeInvestType(5)">
          {{ $t("asset.desc102") }}
        </div>
        <div class="ae-item" :class="{ on: type == 4 }" @click="exChangeInvestType(4)">
          {{ $t("assetbag.desc1") }}
        </div>
      </div>
      <div v-if="showInvest">
        <invest ref="Invest" :type="type" @tab="tab" />
      </div>

      <my-income v-if="type == 5" ref="Myincome" />
      <AssetBag v-if="type === 4" />
    </div>
    <div id="footer">
      <glFooter :act="4" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import h5Top from "@/componets/h5top.vue";
import invest from "./invest.vue";
import myIncome from "./myincome.vue";
import glFooter from "@/componets/footer.vue";
import AssetBag from "./asset-bag";
import store from "@/store";
import bus from "@/utils/bus";
export default {
  components: {
    h5Top,
    invest,
    myIncome,
    glFooter,
    AssetBag,
  },
  computed: {
    ...mapState(["assetType"]),
    type: {
      get() {
        return this.assetType;
      },
      set(e) {
        store.commit("SET_ASSETTYPE", e);
      },
    },
  },
  data() {
    return {
      drawData: [],
      datainfo: [],
      showInvest: false,
      showActivity:1
    };
  },
  mounted() {
    // this.showActivity=localStorage.getItem('showActivity');
    // if (localStorage.getItem('ismobile') == 0 &&  this.showActivity == 0) {
    //   bus.emit("handlerShow", true);
    // }
    // bus.on("closeActivity", (e) => {
    //     this.showActivity=1;
    //   });
    if (this.type == 1 || this.type == 2 || this.type == 3) {
      this.showInvest = true;
    }
  },
  // watch: {
  //   type: {
  //     handler(e) {
  //       this.$nextTick(() => {
  //         if (e == 1 || e == 2 || e == 3) {
  //           this.$refs.Invest.init(e);
  //         } else {
  //           this.$refs.Myincome.init();
  //         }
  //       });
  //     },
  //     immediate: true,
  //   },
  // },
  methods: {
    tab(i){
      this.type=4;
      this.showInvest = false;
   
    },
    exChangeInvestType(type) {
      this.type = type;
      this.showInvest = false;
      if (type == 1 || type == 2 || type == 3) {
        setTimeout(() => {
          this.showInvest = true;
        }, 100);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.asset {
  width: 100%;
  padding: 136px 0 80px;
  background: url("../../public/images/new/landbg.png") center no-repeat;
  background-size: 100% 100%;

  .asset-ex {
    width: 100%;
    display: flex;
    margin-bottom: 24px;
    border-bottom: 1px solid #eeeeee33;

    .ae-item {
      height: 41px;
      font-family: Bai Jamjuree;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      margin-right: 36px;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.6);

      &.on {
        color: #fff;
        position: relative;

        &::before {
          display: block;
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 1px;
          background: #c9fa5b;
        }
      }
    }
  }

  .asset-wrap {
    width: 1240px;
    margin: 0 auto;
  }
}
.maxtop{
  padding-top: 188px;
}

@media (max-width: 1200px) {
  .asset {
    padding: 88px 0 80px;

    .asset-wrap {
      width: calc(100vw - 8px);
    }
  }
}

@media (max-width: 767px) {
  .asset {
    padding: 56px 0 70px;
    background: #000;

    .asset-ex {
      display: flex;

      margin-bottom: 18px;
      overflow-x: scroll;
      overflow-y: hidden;

      .ae-item {
        font-size: 14px;
        height: inherit;
        margin-right: 0;
        flex: 0 0 25%;
        text-align: center;
        padding-bottom: 16px;
      }

      .on {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 20%;
          width: 60%;
          height: 3px;
          background: #c9fa5b;
          border-radius: 10px;
        }
      }
    }

    .asset-wrap {
      padding: 0 16px 0;
      width: 100%;
    }
  }
}
</style>