<template>
  <div class="account-data">
    <div class="item">
      <div class="name">
        {{ $t("assetbag.desc88", { n: statistics.fillCoinSymbol }) }}
        <!-- <tooltip :content="" /> -->
      </div>
      <div class="val">{{ common.cutXiaoNum1(statistics.balance) }}</div>
    </div>
    <div class="item">
      <div class="name">
        {{ $t("assetbag.desc89", { n: statistics.fillCoinSymbol }) }}
        <tooltip :content="$t('assetbag.desc122')" />
      </div>
      <div class="val">{{ common.cutXiaoNum1(statistics.totalLossAmount) }}</div>
    </div>
    <div
      class="btn"
      :class="{ disbale: !canBackfillAble || Number(statistics.balance) === 0 }"
      @click="handleBackfill"
    >
      {{ $t("assetbag.desc119") }}
    </div>
    <div class="tip">
      {{ $t("assetbag.desc118", { n: statistics.fillCoinSymbol }) }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    statistics: Object,
    canBackfillAble: Boolean,
  },
  methods: {
    handleBackfill() {
      if (Number(this.statistics.balance) === 0) {
        this.$message(this.$t("assetbag.desc50"));
        return;
      }
      if (!this.canBackfillAble) {
        this.$message(this.$t("assetbag.desc125"));
        return;
      }
      this.$emit("backfill");
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  margin-top: 28px;
  padding-right: 10px;
  box-sizing: border-box;
  &:last-child,
  &:nth-child(4) {
    padding-right: 0;
  }
  .name {
    min-height: 1.3em;
    color: #a8a8a8;
    //font-family: Bai Jamjuree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3em;
    text-transform: capitalize;
    word-break: break-all;
  }
  .val {
    margin-top: 6px;
    min-height: 1.2em;
    color: #fff;
    //font-family: Bai Jamjuree;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2em;
    &.view {
      color: #c9fa5b;
      cursor: pointer;
    }
  }
}
.account-data {
  width: 400px;
  border-radius: 12px;
  background: #181818;
  padding: 2px 32px 32px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: 14px;

  .item {
    width: 50%;
    &:nth-child(2) {
      padding-right: 0;
    }
  }
  .btn {
    margin-top: 22px;
    width: 50%;
    height: 44px;
    color: #000;
    text-align: center;
    //font-family: Bai Jamjuree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1em;
    text-transform: capitalize;
    box-sizing: border-box;
    //font-family: Bai Jamjuree;
    cursor: pointer;
    background: url("../../../../public/images/asset/assetbag/btn1_bg.png")
      center no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disbale {
      background-image: url("../../../../public/images/asset/assetbag/btn_disable_big.png");
    }
  }
  .tip {
    margin-top: 12px;
    width: 100%;
    color: #a8a8a8;
    //font-family: Bai Jamjuree;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    text-transform: capitalize;
  }
}

@media (max-width: 1200px) {
  .account-data {
    width: 320px;
    padding: 2px 24px 24px;
  }
}
@media (max-width: 992px) {
  .account-data {
    width: 248px;
    .item {
      width: 100%;
    }
    .btn {
      width: 100%;
    }
  }
}
@media (max-width: 767px) {
  .account-data {
    padding: 2px 20px 20px;
    margin-bottom: 20px;
    margin-left: 0;
    width: 100%;
    .item {
      margin-top: 18px;
      width: 50%;
      &:last-child {
        width: 100%;
      }
      .btn {
        background-image: url("../../../../public/images/asset/assetbag/btn1_bg_min.png");
      }
    }
    .btn {
      width: 100%;
      height: 40px;
      font-size: 14px;
    }
  }
}
</style>
