<template>
  <div class="detail">
    <div class="title">{{ $t('myincome.desc57') }}</div>
    <div class="wrap">
      <div class="wrap-til">{{ $t('myincome.desc58') }}<span>{{ info.earningDate }}</span></div>
      <div class="wrap-til">{{ $t('myincome.desc59') }}<span class="on">{{ info.earningTotalAmount }}{{ info.earningCoinName }}</span></div>
      <div class="wrap-til">{{ $t('myincome.desc60') }}<span>{{ info.userLevelName }}</span></div>
      <div class="wrap-til">{{ $t('myincome.desc61') }}<span>{{ info.teamAward }}</span></div>
    </div>
    <div class="myincome-table animate__animated animate__fadeInUp animate__delay-.6s">
      <div class="table-thead flex">
        <div>{{ $t('myincome.desc14') }}</div>
        <div>{{ $t('myincome.desc19') }}</div>
        <div>{{ $t('myincome.desc53') }}</div>
        <div>{{ $t('myincome.desc55') }}</div>
        <div>{{ $t('myincome.desc32') }}</div>
        <div>{{ $t('myincome.desc52') }}</div>
      </div>
      <div class="table-tbody">
        <div class="tbody-list flex" v-for="item in info.voList" :key="item.id">
          <div>{{ dealiIncomeType(detailItem.incomeType) }}</div>
          <div>{{ item.earningAmount }}{{ item.earningCoinName }}</div>
          <div>{{ $t('myincome.desc56',{n1:item.hierarchy}) }}</div>
          <div>{{ item.earningAmount }}</div>
          <div>{{ item.earningRate * 100 + '%' }}</div>
          <div>{{ dealiIncomeStatus(item.status) }}</div>
        </div>
      </div>
      <div class="no-data" v-if="showNull">
        <img src="../../../public/images/no-data.png" alt="">
        <span>{{ $t('record.desc13') }}</span>
      </div>
    </div>
    <!-- <div class="tips">
      <p></p>
      <p></p>
      <p></p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from "@/store";
export default {
  computed: {
    ...mapState(['assetType']),
  },
  props: ['detailItem'],
  data() {
    return {
      info: {},
      showNull: false
    }
  },
  mounted() {
    this.handlerInfo(this.detailItem)
  },
  methods: {
    dealiIncomeType(type) { // 1-土地收益 2-土地税收  3-星球推荐奖 4-代数奖励
      if (type == 1 || type == 6) {
        return this.$t("myincome.desc2");
      } else if (type == 2) {
        return this.$t("myincome.desc30");
      } else if (type == 3) {
        return this.$t("myincome.desc31");
      } else if (type == 11 || type == 16) {
        return this.$t("myincome.desc64");
      } else if (type == 12) {
        return this.$t("myincome.desc65");
      } else if (type == 13) {
        return this.$t("myincome.desc66");
      } else if (type == 14) {
        return this.$t("myincome.desc67");
      } else if (type == 20) {
        return this.$t("myincome.desc68");
      }else if (type == 30) {
        return this.$t("market.desc161");
      } else if (type == 40) {
        return this.$t("market.desc207") + ' ' + 'DU';
      }
      else if (type == 41) {
        return this.$t("market.desc219");
      } else if (type == 42) {
        return this.$t("market.desc207") + ' ' + 'RC';
      } else {
        return this.$t("myincome.desc32");
      }
    },
    dealiIncomeStatus(type) { // 状态： 2- 待领取 3-已领取 5-已损失
      if (type == 2) {
        return this.$t('myincome.desc35')
      } else if (type == 3) {
        return this.$t('myincome.desc36')
      } else {
        return this.$t('myincome.desc37')
      }
    },
    handlerInfo(item) {
      let url = this.URL.myincome.detail
      if (item.incomeType == 4) url = this.URL.myincome.teamEarningDetail
      this.$post(url, {
        sn: item.sn
      }).then(res => {
        if (res.code == 0) {
          this.info = res.data;
          if (this.info.voList.length == 0) {
            this.showNull = true
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.detail {
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
  }

  .wrap {
    display: flex;
    align-items: center;

    .wrap-til {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-right: 40px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.6);

      span {
        color: #fff;
        &.on {
          color: #C9FA5B;
        }
      }
    }
  }

  .myincome-table {
    margin-top: 24px;

    .table-thead {
      padding: 20px 24px;
      background: #232323;
      font-size: 13px;
      color: rgba(255, 255, 255, .6);

      div {
        flex: 1;

        &:last-child {
          flex: 0 0 80px;
          text-align: right;

        }
      }
    }

    .table-tbody {
      max-height: 590px;
      overflow-y: scroll;

      &::-webkit-scrollbar {

        display: none;

      }

      .tbody-list {
        padding: 22px 24px;
        font-size: 13px;
        color: #BBBBBB;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);

        &:hover {
          background: #181818;
        }

        div {
          flex: 1;

          &:last-child {
            flex: 0 0 80px;
            cursor: pointer;
            justify-content: flex-end;
            text-align: right;
            img {
              width: 10px;
              height: 12px;
              margin: 3px 0 0 4px;
            }
          }
        }
      }
    }

    .no-data {
      width: 100%;
      padding: 100px 0;

      img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
      }

      span {
        display: block;
        margin: 0 auto;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #A1A1A1;
      }
    }
  }
}</style>
